import {
  SYNC_ROOMS, SET_CLIENT
  } from "../actions/types";
  const initialState = [];
  function roomSyncReducer(allRooms = initialState, action) {
    const { type, payload } = action;
    // alert("messaging sdk reducer    " + JSON.stringify(payload))
    // console.log("messaging sdk reducer    " + JSON.stringify(payload))
    
    switch (type) {
      case SYNC_ROOMS:
      return {
          allRooms: payload,
          time: Date.now(),
        };
        case SET_CLIENT:
          // alert("reducer setClient " + JSON.stringify(payload));

          return {
            client: payload,initialState
          };
    
      default:
        return allRooms;
    }
  };
  export default roomSyncReducer;