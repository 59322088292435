import {
  AUTHENTICATE,
  REGISTER,
  LOGOUT,
  } from "./types";
  import AuthDataService from "../services/auth.service";

  export const authenticate = (userForAuthentication) => async (dispatch) => {
    //console.log("payloads",userForAuthentication)
    userForAuthentication={
    username:userForAuthentication.user_email,
    password:userForAuthentication.user_password
    }
    //console.log("payloadsafter",userForAuthentication)

    try {
      const res  = await AuthDataService.authenticate(userForAuthentication);
      console.log("AuthDataService",res.data.response.result)
      // alert("res   "+JSON.stringify(res.data.response.detail))
      if(res?.status===200)
      {

      
      dispatch({
        type: AUTHENTICATE,
        payload: res.data.response.result,
        isLoggedIn: true,
      });
    }
    else
    {
      alert("Some thing went wrong during login")
    }
      return res.data.response.detail;
  
    }
    catch(err) {
// alert(err.stack)
// alert("ahmad")
      return Promise.reject(err);

    }
  };
  export const register = (payload) => async (dispatch) => {
    try {
      // const newUserForRegistration = {
      //   username,
      //   password,
      // }
      const res = await AuthDataService.register(payload);
      dispatch({
        type: REGISTER,
        payload: res.data.response,
      });
      return Promise.resolve(res.data.response);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  export const logout = () => async (dispatch) => {
    try {
      // const res = await AuthDataService.register(newUserForRegistration);
      dispatch({
        type: LOGOUT,
        payload: null,
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  