import React, { useEffect, useState } from "react";
import LeftPaneListItem from "./LeftPaneListItem";
import NewChat from "./NewChat";
import NewGroup from "./NewGroup";
import ExistingRoomJoin from "./ExistingRoomJoin";
import { useGlobalContext } from "../../ContextGlobal/ContectGloval";
import Search from "../../commen/search";
import moment from "moment";
import { RiAddLine } from "react-icons/ri";
import "./leftPaneCss/leftPane.css";

const LeftPane = (props) => {
    const [newChatOpen, setNewChatOpen] = useState(false);
    const [newGroupOpen, setNewGroupOpen] = useState(false);
    const [searchName, setSearchName] = useState();
    const [forFilter, setFilter] = useState();
    const [dropDownActive, setDropDownActive] = useState(false);
    const [searchOpen,setSearchOpen]=useState(false)

    const context = useGlobalContext();

    const [existRoomJoinOpen, setExistRoomJoinOpen] = useState(false);
    let { rooms, createGroupCallBack } = props;
    const [roomName, setRoomName] = useState();
 

    // alert("546 rooms===="+JSON.stringify(rooms))
    let i = 0;
   // console.log("563=============", props.rooms);
    let localRoomsArray = [];
    if (roomName && roomName?.length > 0) {
        localRoomsArray = [...roomName];
    }
    useEffect(() => {
        setRoomName(rooms);
        context?.setGetRoom(rooms);
        setFilter(rooms);
    }, [rooms]);
    const filterTime = (data) => {
        let lastElement
        if(data){
         lastElement = data[data?.length - 1];
        const time = moment(lastElement?.origin_server_ts).format("hh:MM");

       // console.log("lastMessage", data);
        return time;
        }
    };
    const filterMessage = (data) => {
       // console.log("DATA FOR LAST MSG:", data)
      
        if (data?.length) {
            let lastElement = data[data?.length - 1];
            let a=  data.find((ab)=>{

                return ab.event.event_id==lastElement?.content?.['m.relates_to']?.event?.event_id
                        })
                       // console.log("roooms", a);

            let obj = {
                msgType: lastElement?.content?.msgtype,
                msgBody: lastElement?.content?.body,
                isRead: lastElement?.isRead,
                reaction:lastElement?.content?.['m.relates_to']?.key,
                reactmsg:a
            };

            return obj;
        }

    };
  

const onClickChange=()=>{
    // alert("called")
    //// console.log("searchName",searchName?.length)
    if(searchName?.length=='0'||searchName?.length==undefined)
    {
        setSearchOpen(!searchOpen)
    }
}
    const onHandleChange = (event) => {
        // Access input value
        const query = event.target.value;
        setSearchName(query);
        // Create copy of item list
        var updatedList = [...forFilter];
        // Include all elements which includes the search query
        updatedList = updatedList.filter((item) => {
            //// console.log("filter")
            return (
                item.name
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toLowerCase()) > -1
            );
        });
        // Trigger render with updated values
        setRoomName(updatedList);

       // console.log("588aaaa ", updatedList);
    };
    
    return (
        <div className="lp_main">
            {/* <div className="">
                <LeftSearchPane setSearchName={onHandleChange} />

                <div className="d-flex justify-content-between text-white p-3">
                    <h4 style={{ color: "#d7d7d7" }}>Dashboard</h4>
                    <Dropdown>
                        <Dropdown.Toggle className="border-0 bg-transparent">
                            <i
                                style={{ color: "#d7d7d7" }}
                                className="fa fa-plus"
                            ></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setNewChatOpen(true)}>
                                New Chat
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => setNewGroupOpen(true)}
                            >
                                New Group
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setExistRoomJoinOpen(true)}>Join existing Room</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div> */}
            <div className="lpm_header">
                <p className="lpmh_msg_text">Message</p>

                <div
                    className="lpmh_btn_container"
                    onClick={() => setNewGroupOpen(true)}
                    // onClick={() => setNewChatOpen(true)}
                    // onClick={() => setDropDownActive(!dropDownActive)}
                >
                    {/* <p className="lpmh_btn_text">New Chats</p> */}
                    <div style={{backgroundColor:"#19C39F",width:"23px",height:"23px"}} className="lmph_btn_icon_container">
                        <RiAddLine  className="lpmh_btn_icon" />
                    </div>


                    {
                        // dropDownActive ? (
                        //     <div className="dropDown_main">
                        //         {/* <p className="dropDown_options" onClick={() => setNewChatOpen(true)}>
                        //             New Chat
                        //         </p> */}
                        //         <p className="dropDown_options" onClick={() => setNewGroupOpen(true)}>
                        //             New Group
                        //         </p>
                        //     </div>
                        // ) :
                        //     null
                    }


                </div>


            </div>

            <div className="lpm_searchBox">
            {/* onClickChange={onClickChange} */}
                <Search  onHandleChange={onHandleChange} placeholder="Search" />

            </div>
            {searchOpen&&
            <div style={{background:"white",height:"80%",width:"100%",borderRadius:"5px",position:"absolute"}}>


            </div>
}

            <div  className=" members_section chat_members d-flex">
                <ul className="list-unstyled text-white">
                    {roomName &&
                        React.Children.toArray(
                            roomName.map((room, i) => {
                                // alert(unreadMessages.length)
                                // filterTime(room?.messages)
                                return (
                                    <LeftPaneListItem
                                        //key={i}
                                        index={i}
                                        room={room}
                                        members={props.rooms}
                                        text={room.name}
                                        name={room.name}
                                        time={filterTime(room?.messages)}
                                        lastMessage={props.rooms}
                                        // lastMessage={filterMessage(
                                        //     room?.messages
                                        // )}
                                    />
                                );
                            })
                        )}
                </ul>
                {newChatOpen ? (
                    <NewChat
                        setNewChatOpen={setNewChatOpen}
                        newChatOpen={newChatOpen}
                    />
                ) : (
                    ""
                )}
                {newGroupOpen ? (
                    <NewGroup
                        setNewGroupOpen={setNewGroupOpen}
                        newGroupOpen={newGroupOpen}
                        createGroupCallBack={createGroupCallBack}
                    />
                ) : (
                    ""
                )}
                {existRoomJoinOpen ? (
                    <ExistingRoomJoin
                        open={existRoomJoinOpen}
                        setOpen={setExistRoomJoinOpen}
                        rooms={roomName}
                    />
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default LeftPane;
