import React, { useState, useRef,useEffect } from "react";
import SendIcon from "@material-ui/icons/Send";
import { Tooltip } from "@material-ui/core";
import Audio from "../../../assets/svg/audio.svg";
import { Button } from "react-bootstrap";

import { useGlobalContext } from "../../ContextGlobal/ContectGloval";
export default function CaseAudio({
    sendAudioCallBack,
    room,
    replyUploadFileCallback
}) {
    const inputRef = useRef(null);
    const hiddenFileInput = React.useRef(null);

    const context = useGlobalContext();
    const [selectedFile, setSelectedFile] = useState();
    const [selected, setSelected] = useState();
    const handleSubmitAudio = (e) => {
        //console.log("wawawa", e.target.files[0].name);
        setSelected(URL.createObjectURL(e.target.files[0]));
        setSelectedFile(e.target.files[0]);
    };

    useEffect(() => {
        // console.log("i am called", context.dropImage);
        // alert("called")
        if(context?.openInputAuto==="audio")
        document.getElementById("abc").click()
        
       
    }, [context.openInputAuto]);
    const handleAudioSend = () => {
        // let content = {
        //   msgtype: "m.audio",
        //   selectedFile
        // };
       // console.log("wawawa", selectedFile);
        if (context.replyMessage === "") {
            sendAudioCallBack(room, selectedFile);
        } else {
            replyUploadFileCallback(
                room.roomId,
                context.replyMessage,
                selectedFile
            );
        }
        context.setReplyMessage("");
        // sendAudioCallBack(room, selectedFile);
        // inputRef?.current?.value = null;
        setSelectedFile();
        setSelected(false);
        context.setOpenWrapper(false);
    };

    return (
        <div className="d-flex flex-column align-items-center text-center w-100">
            
            <h4 className="w-50">Audio</h4>
            <img src={Audio} />
            {/* <h6 className="w-50 mt-1">Select audio file</h6> */}
            {!selectedFile && (
<>
            <Button
                onClick={() => {
                    hiddenFileInput.current.click();
                }}
                style={{width:"150px"}}
                className="mt-3 btn btn-primary"
                htmlFor="filePicker"
            >
                Upload audio
            </Button>
            <div>
                <input
                id="abc"
                    type="file"
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    name="myAudio"
                    accept="audio/*"
                    onChange={handleSubmitAudio}
                />
            </div>
            </>
)}
            {selected && (
                <div className="d-flex mt-3">
                    {/* <h5>{selected}</h5> */}
                    <audio controls>
                        <source src={selected} type="audio/ogg" />
                        Your browser does not support the audio element.
                    </audio>
                    <label
                        className="ms-3 text-danger"
                        onClick={() => {
                            setSelectedFile();
                            setSelected();
                            // inputRef.current.value = null;
                        }}
                    >
                        X
                    </label>
                </div>
            )}
            <Tooltip title="Sent">
            <div className="sendButton">
                    <button
                        onClick={handleAudioSend}
                        className="btnSend-primary "
                        
                        disabled={selectedFile ? false : true}
                    >
                        <SendIcon />
                    </button>{" "}
                </div>
            </Tooltip>
        </div>
    );
}
