import React, { useState } from "react";
import "./styles.css";
import Logo from "../../assets/images/kuumba_logo.png";
import Placeholder from "../../assets/svg/placeholder.svg";

import placeholder2 from "../../assets/images/2.png";
import PopoverReuse from "../desktop/DesktopComponents/ReusableComponnet/Popover";
import { MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ProfileEdit from "./profileEdit";
import matrixcs from "matrix-js-sdk";
function TopBar(props) {
    const {
        image,
        expandDropdown,
        darkOn,
        valueDark,
        leftPanelHide,
        setAvatarUrl
    } = props;
    const [expand, setExpand] = useState(false);
    const [dropDown, setDropDown] = useState(false);
    const [opens, setOpen] = useState(false);
    let helloText = valueDark ? "Light Mode" : "Dark Mode";
    // let userName = "Noman qadir";
    // setLeftBarHide
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const LogOut = () => {
        dispatch(logout());
        history.push("/");
        localStorage.clear();
        var req = indexedDB.deleteDatabase("matrix-js-sdk:default");
        req.onsuccess = function () {
            console.log("Deleted database successfully");
        };
        req.onerror = function () {
            console.log("Couldn't delete database");
        };
        req.onblocked = function () {
            console.log(
                "Couldn't delete database due to the operation being blocked"
            );
        };
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const userName = (val) => {
        if (val?.includes("_")) {
            return val?.split(":")[0].split("@")[1].split("_")[0];
        }

        return val?.split(":")[0].split("@")[1];
    };
    const toglleHandler = () => {
        expandDropdown();
        setExpand(!expand);
    };
    const dropDownHandler = () => {
        setDropDown(!dropDown);
    };
    return (
        <>
            <div className="topbar">
                <div className="topbar_left">
                    <img
                        src={image ? Placeholder : Logo}
                        alt="logo"
                        className="topbar_logo_"
                    />
                    <div className="buttonShow">
                        <button onClick={() => leftPanelHide()}>ahmad</button>
                    </div>
                    {/* <BsList
                        className={`topbar_line_ ${
                            expand
                                ? "line_toggle_toword"
                                : "line_toggle_forword"
                        }`}
                        onClick={toglleHandler}
                    /> */}
                </div>
                <div className="topbar_Middle">
                    <div className="topbar_search_box">{/* <Search /> */}</div>
                </div>
                <div className="topbar_right">
                    {/* <div onClick={() => darkOn()} className="dark_light_toggle">
                        <BsMoonFill />
                    </div> */}
                    {/* <div className="topbar_title ">{helloText}</div> */}
                    <div onClick={handleClick} className="img-sec me-2">
                        <img
                            style={{ width: "30px", height: "30px" }}
                            src={placeholder2}
                            className="rounded-circle"
                            width="150px"
                            alt="Avatar"
                        />

                        {/* style={{width: 40, height: 40, borderRadius: 40/ 2}}  */}
                        {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGLAUGhvGs1mg90ntWQRy0zWejCoWb7fw3ZlZvUCEj3OAtoAWfnlVtiHo7wLptXg451-E&usqp=CAU" /> */}
                    </div>
                    <PopoverReuse
                        id={id}
                        open={open}
                        // anchorPosition={{ top: 228, left: 518 }}
                        // anchorReference="anchorPosition"

                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                        cssClass={"topbar-logout"}
                    >
                        <div>
                            <MenuItem>
                                <p
                                    onClick={() => {
                                        setOpen(true);
                                        handleClose();
                                    }}
                                    className="fa fa-user-plus"
                                >
                                    {" "}
                                    Profile
                                </p>
                            </MenuItem>
                            <MenuItem>
                                <p onClick={LogOut} className="fa fa-sign-out">
                                    {" "}
                                    Log Out
                                </p>
                            </MenuItem>
                        </div>
                    </PopoverReuse>

                    <div className="topbar_title ">
                        {userName(localStorage.getItem("mxvr_user_id"))}
                    </div>
                    {/* <div
                        className={`topbar_arrow ${
                            dropDown
                                ? "line_toggle_toword"
                                : "line_toggle_forword"
                        }`}
                        onClick={dropDownHandler}
                    >
                        <img src={Arrow} alt="logo" className="arrow_" />
                    </div> */}
                </div>
                {/* {dropDown ? (
                    <div className="logout_box">
                        <LogOutDropDown />
                    </div>
                ) : (
                    ""
                )} */}
                <ProfileEdit
                    opens={opens}
                    setOpen={setOpen}
                    setAvatarUrl={setAvatarUrl}
                    userName={userName(localStorage.getItem("mxvr_user_id"))}
                />
            </div>
        </>
    );
}

export default TopBar;
