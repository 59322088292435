import { withRouter } from 'react-router';

const Settings = () => {
  return(
    <div id="main" className="form-body main">
      Settings
    </div>
  );

}

export default withRouter(Settings);

