import React, { Component, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import TopBar from "./components/topBar";
import 'font-awesome/css/font-awesome.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./components/utils/utils.css";
import Register from "./components/auth/register";
import Authenticate from "./components/auth/authenticate-user.component";
import ProtectedRoutes from "./components/PrivateRoutes";
import Desktop from "./components/desktop/desktop";
import Settings from "./components/settings/settings";
import NavBar from "./components/Navbar/navbar";
import Logout from "./components/auth/logout";

import * as sdk from "matrix-js-sdk";
import { ContextGlobal, SocialAccountProvider } from "./components/ContextGlobal/ContectGloval";
import Popup from "./components/commen/popup";

// const client = sdk.createClient("devm.vjoinlife.com");
// client.login("m.login.password", {"user": "qwerty", "password": "123Lahore!"}).then((response) => {
//     console.log(response.access_token);
// });



const isLoggedIn = () => {
  if (localStorage.getItem("token")) {
    return true;
  } else {
    return false;
  }
}


const Analytics = () => {
  return (
    <div id="main" className="form-body main">
      Analytics
    </div>
  )
}
const Inquiry = () => {
  return (
    <div id="main" className="form-body main">
      Inquiry
    </div>
  )
}

class App extends Component {
  render() {
    //   let allRooms = [];
    //   const messageTypes = ["m.audio", "m.emote", "m.file", "m.image", "m.location", "m.room.message", 
    //   "m.server_notice", "m.text", "m.video", ];

    //   const client = sdk.createClient({
    //     baseUrl: "https://devm.vjoinlife.com",
    //     accessToken: "syt_cXdlcnR5_zgVWVCFQJUjzHdhPcXje_1gVqoj",
    //     userId: "@qwerty:devm.vjoinlife.com"
    //   });
    //   console.log("client", client)
    //   client.startClient();

    //   client.once('sync', function(state, prevState, res) {
    //     console.log(state); // state will be 'PREPARED' when the client is ready to use

    //     var rooms = client.getRooms();
    //     allRooms = rooms;
    //     rooms.forEach(room => {
    //       room.timeline.forEach(t => {
    //       if (messageTypes.includes(t.event.type)){
    //         console.log(JSON.stringify(t.event.content));
    //         allRooms.push(room);
    //       }
    //       });
    //       // var members = room.getJoinedMembers();
    //       //   members.forEach(member => {
    //       //       console.log(member.name);
    //       //   });
    //     });

    // });
    // // client.on("event", function(event){
    // //   console.log(event.getType());
    // //   console.log(event);
    // // })
    // // client.on("Room.timeline", function(event, room, toStartOfTimeline) {
    // //   console.log(event);
    // //   if (event.type == 'm.room.encrypted'){
    // //     // console.log(even);
    // //   }
    // //   console.log(event.event);
    // // });
    // console.log("client========", client);
    // var rooms = client.getRooms();
    // console.log("before get rooms details");
    // rooms.forEach(room => {
    //   console.log("get rooms details");
    //   console.log("room.summary=====", room.summary);
    //   console.log(room.roomName);
    //   console.log(room.roomId);
    // });
    // var rooms = client.getRooms();
    // rooms.forEach(room => {
    //     var members = room.getJoinedMembers();
    //     members.forEach(member => {
    //         console.log(member.name);
    //     });
    // });
    // var rooms = client.getRooms();
    // rooms.forEach(room => {
    //     room.timeline.forEach(t => {
    //         console.log(JSON.stringify(t.event.content));
    //     });
    // });    



    return (
      <Router>



        {/* <NavBar  /> */}
        {/* <p style={{color:"white"}}>sdkdldkdk;k</p> */}
        <Switch>

          <ProtectedRoutes path={["/desktop", "/home"]} component={Desktop} />

          <ProtectedRoutes path="/settings" component={Settings} />
          <ProtectedRoutes path="/logout" component={Logout} />
          <ProtectedRoutes path="/inquiry" component={Inquiry} />
          <ProtectedRoutes path="/analytics" component={Analytics} />

          <Route path="/popup" component={Popup} />
          <Route path="/login" component={Authenticate} />
          <Route path={["/", "/register"]} component={Register} />
        </Switch>

      </Router>
    );
  }
}
export default App;