import React, { useState, useCallback } from "react";
import SendIcon from "@material-ui/icons/Send";
import { Tooltip } from "@material-ui/core";
import VideoRecorder from "react-video-recorder";
import { useGlobalContext } from "../../ContextGlobal/ContectGloval";
import Webcam from "react-webcam";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";

const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user"
};
function CaseCamera({
    sendImageCallBack,
    room,
    sendVideoCallBack,
    replyUploadFileCallback
}) {
    const context = useGlobalContext();
    const webcamRef = React.useRef(null);
    const [selectedFile, setSelectedFile] = useState();
    const [videoRecord, setVideoRecord] = useState(false);
    const [videoUrl, setVideoUrl] = useState();
    const [thumbnail, setThumbnail] = useState();
    const [selectedDocument, setSelectedDocument] = useState(false);
    const [image, setImage] = useState("");

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImage(imageSrc);
    });
    const recVideo = (videoBlob) => {
        setVideoUrl(URL.createObjectURL(videoBlob));
        let numberOfThumbnails = 0;
        generateVideoThumbnails(videoBlob, numberOfThumbnails).then(
            (thumbnailArray) => {
                fetch(thumbnailArray[0])
                    .then((res) => res.blob())
                    .then((blob) => {
                        const file = new File([blob], "img.jpeg");
                        setThumbnail(file);
                    });
            }
        );
    };
    const handleImageSend = () => {
        if (image) {
            fetch(image)
                .then((res) => res.blob())
                .then((blob) => {
                    const file = new File([blob], "img.jpeg");
                    let thumbnail = file;
                    if (context.replyMessage === "") {
                        sendImageCallBack(room, file, thumbnail);
                    } else {
                        replyUploadFileCallback(
                            room.roomId,
                            context.replyMessage,
                            file,
                            thumbnail
                        );
                    }
                    context.setReplyMessage("");
                    setSelectedDocument(false);
                    setSelectedFile();
                    setImage();
                    context.setOpenWrapper(false);
                });
        } else if (videoUrl) {
            try {
                fetch(videoUrl)
                    .then((res) => res.blob())
                    .then((blob) => {
                        const file = new File([blob], "filename.webm");
                        if (context.replyMessage === "") {
                            sendVideoCallBack(room, file, thumbnail);
                        } else {
                            replyUploadFileCallback(
                                room.roomId,
                                context.replyMessage,
                                file,
                                thumbnail
                            );
                        }
                        setThumbnail("");
                        setVideoUrl("");
                        context.setOpenWrapper(false);
                        context.setReplyMessage("");
                    });
            } catch (error) {
                alert(error);
            }
            setVideoRecord(false);
        }
    };
    return (
        <div className="d-flex flex-column align-items-center text-center w-100">
            <h4 className="w-50">Camera & Video</h4>
            <>
                {selectedDocument ? (
                    ""
                ) : (
                    <button
                        style={{ marginTop: "10px",
                        height: "70px",
                        width: "70px",
                        fontSize: "30px",
                        borderRadius: "50%" }}
                        className="btn btn-primary "
                        onClick={() => {
                            setSelectedDocument(true);
                            setSelectedFile();
                            setVideoRecord(false);
                        }}
                    >
                        <i className="fa fa-camera"></i>
                    </button>
                )}
                {selectedDocument && (
                    <div
                        className="webcam-img w-100"
                        style={{ height: "20rem" }}
                    >
                        {image === "" ? (
                            <div className="d-flex flex-column mt-3">
                                <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                    className="w-100"
                                    style={{ height: "20rem" }}
                                />
                                <div className="captureButton">
                                    <button
                                        className="btn btn-primary btn-circle"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            capture();
                                        }}
                                    >
                                        <i className="fa fa-camera"></i>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <img
                                    src={image}
                                    alt="captureImg"
                                    style={{ height: "20rem" }}
                                />

                                <div>
                                    {image != "" ? (
                                        <div className="retakeButton">
                                            <button
                                                className="btn btn-primary btn-circle"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setImage("");
                                                }}
                                            >
                                                <i className="fa fa-refresh"></i>
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            className="btn btn-primary btn-circle"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                capture();
                                            }}
                                        >
                                            <i className="fa fa-camera"></i>
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {videoRecord ? (
                    ""
                ) : (
                    <button
                    style={{marginTop:"124px", 
                    height: "70px",

                    width: "70px",
                    fontSize: "30px",
                    borderRadius: "50%"}}
                        className="btn btn-primary "
                        onClick={(e) => {
                            setVideoRecord(true);
                            setImage("");
                            setSelectedDocument(false);
                        }}
                    >
                        <i className="fa fa-video-camera"></i>
                    </button>
                )}

                {videoRecord && (
                    <>
                        <div className="w-100" style={{ marginTop: "10px" }}>
                            <VideoRecorder
                                onRecordingComplete={(videoBlob) => {
                                    recVideo(videoBlob);
                                }}
                            />
                        </div>
                    </>
                )}
            </>
            <Tooltip title="Sent">
                <div className="sendButton" >
                    <button
                        onClick={handleImageSend}
                        className="btnSend-primary"
                        disabled={
                            image || selectedFile || videoUrl ? false : true
                        }
                    >
                        <SendIcon />
                    </button>{" "}
                </div>
            </Tooltip>
        </div>
    );
}

export default CaseCamera;
