import {
  STATUSES,
  } from "../actions/types";
  const initialState = [];
  function statusesReducer(currentState = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case STATUSES:
          return {
            statuses: payload
          };
        default:
        return currentState;
    }
  };
  export default statusesReducer;