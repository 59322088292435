import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ExistingRoomJoin = (props) => {
      const { open, setOpen, rooms } = props
      const [selectedRoom, setSelectedRoom] = useState("")
      const handleClose = () => {
            setOpen(false)
      }

      const handleSearch = (e) => {
            if (e.key === 'Enter') {
                  const valueFromUser = e.target.value
            }
      }

     // console.log("room_room", rooms)

      return (
            <Modal size="lg" show={open} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                        <Modal.Title>Explore Rooms</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                        <div className="form-outline">
                              <input type="search" placeholder='Search room' className="form-control" onKeyDown={handleSearch} />
                        </div>
                        <div className="list-group overflow-auto vh-30">
                              {/* {
                                    users?.length > 0 ? (
                                          users.map((user, i) => (
                                                <div key={i}>
                                                      <label class="list-group-item">
                                                            <input className="form-check-input me-1" type="checkbox" value="" onChange={() => handleCheckUser(user.matrix_user_id)} />
                                                            {user.user_name}
                                                            <div className="ms-2 me-auto fw-normal">
                                                                  {user.user_email}
                                                            </div>
                                                      </label>

                                                </div>
                                          ))
                                    ) : ""
                              } */}
                        </div>
                  </Modal.Body>

                  <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                        <Button variant="primary">Create Group</Button>
                  </Modal.Footer>
            </Modal>
      )
}

export default ExistingRoomJoin