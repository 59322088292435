import {
  FETCH_DESKTOP, SELECT_ROOM, SEARCH_USER_CREATE_ROOM,TESTING_CASE
} from "../actions/types";
// const initialState = [];
const initialState = {
  tickets: [],
  searchUser: [],
  testingCase:"",
  loading: true
}
function desktopReducer(state = initialState, action) {
  const { type, payload } = action;


  switch (type) {
    case FETCH_DESKTOP:
      // return payload;
      return {
        ...state,
        tickets: action.payload,
        loading: false

      }
            //for testing case only ahmad

      case TESTING_CASE:
      // return payload;
      return {
        ...state,
        testingCase: action.payload,

      }
    case SEARCH_USER_CREATE_ROOM:
      return {
        ...state,
        searchUser: action.payload,
        loading: false
      }
    case SELECT_ROOM:
      // alert("reducer seleect room = "+JSON.stringify(action.payload))

      return {
        room: action.payload,
        selectedRow: action.payload
      }



    default:
      return state;
  }
};
export default desktopReducer;
