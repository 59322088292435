
import { default as matrixcs, RoomMember, TimelineWindow, 
    EventTimeline, EventTimelineSet, Room, RoomEvent } from 'matrix-js-sdk';


import { EventEmitter } from 'events';
import axios from 'axios';
import { serverUrl } from '../url';
var q = require("q");
var Matrix = require("matrix-js-sdk");
var USER_PREFIX = "fs_";
var DOMAIN = "matrix.org";
// var ConferenceCall = require("./ConferenceHandler").ConferenceCall;
// var ConferenceHandler = require("./ConferenceHandler");

// // const fs = require('fs')
// const path = require('path');
// const mime = require('mime');
// const request = require('mime');


const localStorage = window.localStorage;
export const RECEIVED = "RECEIVED";
export const SENT = "SENT";
const messageTypes = ["m.audio", "m.emote", "m.file", "m.image", "m.location", "m.room.message",
"m.server_notice", "m.text", "m.video", "m.reaction","m.room.create","m.room.member",];

export default class Client extends EventEmitter {
    static moi = null;
    constructor(options) {
        super(options);
        Client.moi = this;
        
        this.synced = false;
        this.allRooms = [];
        this.eventMap = new Map();
        this.roomMap = new Map();
        this.matrixRoomMap = new Map();
        this.roomLastPageLoadedMap = new Map();
        

        options = options || {};
        // FIXME - validate
        // baseUrl: "https://devm.vjoinlife.com",
        // accessToken: currentUser.access_token,
        // userId: currentUser.user_id        
        if (options.guest) {
             //this.homeserver = 'https://devm.vjoinlife.com';
            this.homeserver = serverUrl;

            this.guest = true;
        } else {
            // alert("options.user_id====", JSON.stringify(options))
            this.username = options.user_id || '';
            this.userId = options.user_id || '';
            this.password = options.password || '';
            this.accessToken = options.access_token;
            this.homeserver = options.homeserver || '';
        }
        this.peerId = options.peerId || '';
        this.roomAlias = options.roomAlias || '';

        this._persist = options.hasOwnProperty('persistCredentials') ?
            options.persistCredentials : true;

        this._debugLog = this._debugLog.bind(this);
        this._persistCredentials = this._persistCredentials.bind(this);
        this._initClient = this._initClient.bind(this);
        this._syncClient = this._syncClient.bind(this);
        this._makeRoomEventPromise = this._makeRoomEventPromise.bind(this);
        this._makeRoomStateEventPromise = this._makeRoomStateEventPromise.bind(this);
        this._waitForRoom = this._waitForRoom.bind(this);
        this._waitForRoomAndAliases = this._waitForRoomAndAliases.bind(this);

        this.createCall = this.createCall.bind(this);
        this.createRoom = this.createRoom.bind(this);
        this.joinRoomWithAlias = this.joinRoomWithAlias.bind(this);
        this.joinRoomWithId = this.joinRoomWithId.bind(this);
        this.leaveRoom = this.leaveRoom.bind(this);
        this.getDisplayNameForUser = this.getDisplayNameForUser.bind(this);
        this.getRooms = this.getRooms.bind(this);
        this.getRoomAliases = this.getRoomAliases.bind(this);
        this.getRoomIdForAlias = this.getRoomIdForAlias.bind(this);
        this.getDeviceId = this.getDeviceId.bind(this);
        this.matrixUploadFile = this.matrixUploadFile.bind(this);
        this.sendLocation = this.sendLocation.bind(this);


        this._initClient();
    }
    getDeviceId() {
        var id = Math.floor(Math.random() * 16777215).toString(16);
        id = "W" + "000000".substring(id.length) + id;
        if (localStorage) {
            id = localStorage.getItem("mx_device_id") || id;
            localStorage.setItem("mx_device_id", id);
        }
        // alert("id===="+id)
        return id;
    }

    _debugLog(...args) {
       // console.warn(`Client: ${this.username}:`, ...args);
    }

    _persistCredentials() {
        this._debugLog('Persisting credentials to localStorage');
        localStorage.setItem('mxvr_user_id', this.username);
        localStorage.setItem('mxvr_access_token', this.accessToken);
        localStorage.setItem('mxvr_hs_url', this.homeserver);
        if (this.peerId) {
            localStorage.setItem('mxvr_peer_id', this.peerId);
        } else if (localStorage.getItem('mxvr_peer_id')) {
            localStorage.removeItem('mxvr_peer_id');
        }
        if (this.roomAlias) {
            localStorage.setItem('mxvr_conf_room_alias', this.roomAlias);
        }
        localStorage.setItem('mxvr_device_id', this.getDeviceId());
    }

    async _initClient() {

        setTimeout(async () => {
            if (this.accessToken) {
                // alert("83 ======"+this.accessToken);                
                this.userId = localStorage.getItem('mxvr_user_id');
                this.deviceId = localStorage.getItem('mxvr_device_id');
                // alert("86 ======"+this.userId);                
                // alert("87 ======"+this.deviceId);                

                this._debugLog('Logged in successfully');
                if (this._persist) {
                    this._persistCredentials();
                }
                this._syncClient();
                return;
            }

            const loginClient = matrixcs.createClient(this.homeserver);

            let loginPromise;
            if (this.guest) {
                this.username = 'mxvr' + Date.now();
                this._debugLog('Registering guest user:', this.username);
                loginPromise = loginClient.register(
                    this.username,
                    Math.random().toString(36).substring(7),
                    null,
                    { type: 'm.login.dummy' },
                );
            } else {
                this._debugLog('Logging in...');
                loginPromise = loginClient.loginWithPassword(this.username,
                    this.password);
            }
            await this._syncClient();

            // loginPromise.then((data) => {
            //     let localData = data.response.detail;
            //     this.accessToken = localData.access_token;
            //     this.userId = localData.user_id;
            //     this.deviceId = localData.device_id;
            //     this._debugLog('Logged in successfully');
            //     if (this._persist) {
            //         this._persistCredentials();
            //     }
            //     this._syncClient();
            // }, (e) => console.error('ERROR: Failed to log in:', e));
        }, 0);
    }

    async _syncClient() {
        const deviceId = this.getDeviceId();
        let store = new matrixcs.IndexedDBStore({
            localStorage: window.localStorage,
            indexedDB: window.indexedDB,
        });
        await store.deleteAllData();
        await store.startup() // load from indexed db

        const opts = {
            baseUrl: this.homeserver,
            accessToken: this.accessToken,
            userId: this.username,
            deviceId: deviceId,
            // sessionStore: new matrixcs.WebStorageSessionStore(localStorage),
            store: store,

        };
        this.client = matrixcs.createClient(opts);
        this.client.on('sync', async (state, prevState, data) => {
            // alert('hello   '+state)

            switch (state) {
                case 'PREPARED':
                    this._debugLog('Sync completed');
                    this.synced = true;
                    await this.reSync();                    
                    // this.emit('syncComplete');
                    break;

            }
        });

        this.client.on('RoomMember.membership', (event, member) => {
            const room = this.client.getRoom(member.roomId);
            switch (member.membership) {
                case 'invite':
                    if (member.userId === this.userId) {
                        if (!room || room.getJoinedMembers()  
                            .filter((m) => m.userId === this.userId).length === 0) {
                            this._debugLog(`${member.userId} not yet a member` +
                                ` - joining ${member.roomId}`);
                            // auto-join when invited and not a member
                            this.joinRoomWithId(member.roomId)
                                .then((room) => {
                                    this._debugLog(`Auto-joined room ${member.roomId}`);
                                }).catch((e) => console.error(e));
                        }
                    }
                    break;
                case 'join':
                    if (this.synced) {
                        // too chatty
                        // this._debugLog(`Remote user ${member.userId} joined room ${member.roomId}`);
                        this.emit('userJoined', {
                            roomId: member.roomId,
                            userId: member.userId,
                        });
                    }
                    break;
                case 'leave':
                    if (this.synced) {
                        // too chatty
                        // this._debugLog(`Remote user ${member.userId} left room ${member.roomId}`);
                        this.emit('userLeft', {
                            roomId: member.roomId,
                            userId: member.userId,
                        });
                    }
                    break;
                default:
                    break;
            }
        });
        
        this.client.on('Room.myMembership', async (room, membership, prevMembership) => {
            if (prevMembership == 'invite' && membership == 'join'){
                await this.populateRoom(room, this.allRooms);
                this.allRooms = this.sortAndRemoveDuplicatesFromAllRoomsArray();
                this.allRooms.sort(this.comparator);

                this.emit('joinedRoom', {
                    allRooms: this.allRooms,
                });

            }
       //     console.log(`Room.myMembership ==== ${this.userId} membership changeds ${room.roomId}`, membership, prevMembership);
            this.emit('Room.myMembership', room.roomId);
        });
        this.client.on('Room', async (room) => {
       //     console.log(`Room==== ${this.userId} joined ${room.roomId}`);
            // await this.populateRoom(room, this.allRooms);
            // this.allRooms.sort(this.comparator);

            // this.emit('joinedRoom', {
            //     allRooms: this.allRooms,
            // });
        });
        this.client.on('GroupCall.incoming', (call) => {
            alert("hello111")
            if (this.synced) {
                this._debugLog(`Incoming call in room ${call.roomId}`);
                this.emit('incomingCall', call);
            }
        });
        this.client.on('Call.incoming', (call) => {
            alert("hello")
            if (this.synced) {
                this._debugLog(`Incoming call in room ${call.roomId}`);
                this.emit('incomingCall', call);
            }
        });

        this.client.on("RoomMember.typing", (event, member) => {
            var isTyping = member.typing;
            let newMember = new Object();
            newMember.roomId = member.roomId;
            newMember.userId = member.userId;
            newMember.typing = member.typing;
            newMember.name = member.name;
            let currentRoom = this.roomMap.get(member.roomId);
// alert(member.typing);
            let tempAllRoom = []
            if (this.allRooms) {
                tempAllRoom = [...this.allRooms];
            }
            if (currentRoom.roomId == newMember.roomId) {
                currentRoom.member = newMember;
                currentRoom.isTyping=member.typing;


            } 

            // this.sortAndRemoveDuplicates(this.allRooms, 'origin_server_ts');
            this.allRooms = this.sortAndRemoveDuplicatesFromAllRoomsArray();
            this.allRooms.sort(this.comparator);
       //     console.log("204=====newMember=====", this.allRooms)
            this.emit('typing', {
                allRooms: this.allRooms,
            });
        });
        this.client.on('Room.timeline', (event, room, toStartOfTimeline,
            removed, data) => {
            if (this.synced && !toStartOfTimeline && data.liveEvent) {
           //     console.log("273=====this", this)
// alert('274 hello event.getSender()='+event.getSender()+' this.userId='+this.userId)
                let messageReceived = new Object();
                // let tempAllRoom = []
                let currentRoom = this.roomMap.get(room.roomId);
           //     console.log("279=====, ", this.roomMap)
           //     console.log("279=====, ", currentRoom)
           //     console.log("279=====, ", room)
                switch (event.getType()) {
                    case 'm.reaction':
                        messageReceived.content = event.getContent();
                        messageReceived.event_id = event.event.event_id;
                        messageReceived.origin_server_ts = event.event.origin_server_ts;
                   //     console.log("293===event.origin_server_ts===", messageReceived.origin_server_ts)
                        messageReceived.room_id = room.roomId;
                        messageReceived.sender = event.getSender();
                        if (!messageReceived.sender || messageReceived.sender==undefined){
                            messageReceived.sender = this.userId;
                        }                        
                        if (messageReceived.sender == this.userId) {
                            messageReceived.receivedOrSent = SENT;
                        } else {
                            messageReceived.receivedOrSent = RECEIVED;
                        }
                        room.getMembers().forEach((member) => {

                            if (member.user?.userId == messageReceived.sender ){
                                messageReceived.sender_name =  member?.name;
                            }
                
                        });                        
                        messageReceived.type = event.getType();
                   //     console.log("296===", room,  messageReceived.event_id);
                        messageReceived.isRead = this.hasUserReadEvent(room, messageReceived.event_id);

                   //     console.log("298-----", currentRoom, room)
                        if (currentRoom.roomId == room.roomId) {
                            //found it
                            let newCurrentRoomMessages = [];
                            if (currentRoom.messages.length) {
                                newCurrentRoomMessages = [...currentRoom.messages];//clone array
                            }
                       //     console.log("290====" ,currentRoom.messages)
                            currentRoom.messages.push(messageReceived);
                            this.eventMap.set(messageReceived.event_id, messageReceived);
                            currentRoom.lastMessageReceived=messageReceived;
                            
                            currentRoom.event=event.event;
                       //     console.log("315====", messageReceived);
                            const originalEvent = room.findEventById(messageReceived['content']['m.relates_to']['event_id']);
                            currentRoom.lastMessageReceived.originalEvent=originalEvent;
                            currentRoom.typing = false;
                       //     console.log("419======tempAllRoom " ,this.allRooms);
                        }
                        this.eventMap.set(messageReceived.event_id, messageReceived);
                            if (messageReceived && event.getType() == 'm.reaction'){
                           //     console.log("324======", messageReceived.content)
                                if (messageReceived.content['m.relates_to']){
                                   //     console.log("326======", messageReceived.content['m.relates_to'].event_id)
                                        let originalEventModded = this.eventMap.get(messageReceived.content['m.relates_to'].event_id);
                                   //     console.log("328======", originalEventModded)
            
                                        let reactions = originalEventModded.reactions;
                                   //     console.log("331======", reactions)
    
                                        if (!reactions || reactions.length < 1) {
                                            reactions = [];
                                        }
                                   //     console.log("336======", reactions)
                                        var reactionAlreadyExist = reactions.find(function(arrayMessage, index) {
                                            if(messageReceived.sender == arrayMessage.sender && messageReceived.content?.["m.relates_to"]?.key == arrayMessage.content?.["m.relates_to"]?.key)
                                                return true;
                                        });
                                   //     console.log("362 reactionAlreadyExist===", reactionAlreadyExist)
                                        if (!reactionAlreadyExist){
                                            reactions.push(messageReceived);
                                        }
                        
                                        originalEventModded.reactions = reactions;
                                        let BreakException = {};
                                        try {
                                            this.allRooms.forEach((roomLocal) => {
                                           //     console.log("362====", roomLocal, messageReceived);
                                                if (roomLocal.roomId == room.roomId){
                                               //     console.log("364====", messageReceived);
                                                    let i = 0;
                                                    roomLocal.messages.forEach((tempMessage) => {
                                                   //     console.log("366====", originalEventModded, tempMessage);
                                                        if (originalEventModded.event.event_id == tempMessage.event_id){
                                                            tempMessage = originalEventModded;
                                                       //     console.log("367======", tempMessage);
                                                            roomLocal.messages[i]=tempMessage;
                                                            // currentRoom.lastMessageReceived = tempMessage;
                                                            throw BreakException;
                                                        }
                                                        i++;
                                                    })                                                
                                                }
                                            })
    
                                        } catch (e) {
                                            if (e !== BreakException) throw e;
                                        }
                                   //     console.log("340======", originalEventModded)
                                    }
                            }                        
                        this.allRooms = this.sortAndRemoveDuplicatesFromAllRoomsArray();
                        this.allRooms.sort(this.comparator);

                        // this.allRooms = tempAllRoom;
                   //     console.log("347======", this.allRooms);

                        this.emit('message', {
                            allRooms: this.allRooms,
                        });                        
                        break;                    
                    case 'm.room.message':

                    this.processMessageReceived(event, messageReceived, room, currentRoom);                        
                        break;
                    default:
                }
           //     console.log("379 tempAllRoom", this.allRooms)
            }
        });
   //     console.log("rooms===363====", this.allRooms);
   //     console.log("408=====this.client", this.client);
        this.client.on("Room.receipt", function(event, matrixRoom){
          //  console.log("438=====", event, matrixRoom);
             var receiptContent = event.getContent();
             let room = this.roomMap?.get(room?.roomId);
             let allRooms = Client.moi.allRooms;
             const allKeys = Object.keys(event.getContent());

             Client.moi.allRooms.forEach((room) => {
          
                if (room.roomId == matrixRoom.roomId){
                    //found it
                    let newMessages = [];
                    room.messages.forEach(message => {
                        allKeys.forEach( key => {
                            if (key == message.event_id || key == message.event.event_id ){
                                //found it
                                let readBy = Object.keys(event.getContent()[key]['m.read']);
                                // alert(readBy)
                                // alert(Client.moi.userId)
                                if (readBy == Client.moi.userId){
                                    message.isRead = true;
                                    console.log("438=====1111", message);
                                }
                                let readByMembers = message.readByMembers;
                                readByMembers.forEach((readByMember) => {

                                    if (readByMember.user_id==readBy) {
                                        readByMember.hasRead = true;
                                    }
                                });
                                let a = Client.moi.allRooms;
                                Client.moi.emit('receipt', {
                                    allRooms: Client.moi.allRooms,
                                });
                            }
                        });
                        message.isRead = Client.moi.hasUserReadEvent(room, message.event_id);
                        newMessages.push(message);
                    });
                    room.messages = newMessages;
                    console.log("477====", room, newMessages);
                }
             });


            //  });

         });        
        this.client.on("RoomMember.membership", function (event, member) {
            if (member.membership === "invite" && member.userId === this.userId) {
                this.client.joinRoom(member.roomId).then(function () {
               //     console.log("Auto-joined %s", member.roomId);
                });
            }
        });

        await this.client.startClient({ initialSyncLimit: 1000 });
        // await this.client.startClient();
    }
    async reSync() {
   //     console.log("486====reSync");
        let allRooms = await this.getRooms();
   //     console.log("488====reSync", allRooms);
        allRooms.sort(this.comparator);
        this.emit('syncComplete', {
            allRooms: allRooms,
        });
    }

    processMessageReceived(event, messageReceived, room, currentRoom) {
        console.log("521 processMessageReceived=====", this.allRooms, event, messageReceived, room, currentRoom);

   //     console.log("374======", event);
   //     console.log("375======event.getContent()  ", event.getContent());
        messageReceived.content = event.getContent();
   //     console.log("377======messageReceived  ", messageReceived);
   //     console.log("378======event.status  ", event.status);
   //     console.log("379======event.getId()  ", event.getId());
   //     console.log("380======event  ", event);

        messageReceived.event = event.event;
        messageReceived.event_id = event.event.event_id;
        messageReceived.origin_server_ts = event.event.origin_server_ts;
   //     console.log("385===event.origin_server_ts===", messageReceived.origin_server_ts);
        messageReceived.room_id = room.roomId;
   //     console.log("387===event.getSender()===", event.getSender());
   //     console.log("388===messageReceived.sender===", messageReceived.sender);

        messageReceived.sender = event.getSender();
        if (!messageReceived.sender || messageReceived.sender == undefined) {
            messageReceived.sender = this.userId;
        }
        // alert('365 hello event.getSender()='+event.getSender()+' this.userId='+this.userId)
        // alert('366 hello messageReceived.sender == this.userId='+(messageReceived.sender == this.userId))
        if (messageReceived.sender == this.userId) {
            messageReceived.receivedOrSent = SENT;
        } else {
            messageReceived.receivedOrSent = RECEIVED;
        }
        room.getMembers().forEach((member) => {

            if (member.user?.userId == messageReceived.sender ){
                messageReceived.sender_name =  member?.name;
            }

        });          
        messageReceived.type = event.getType();
   //     console.log("402", room, messageReceived.event_id);
        messageReceived.isRead = this.hasUserReadEvent(room, messageReceived.event_id);

        let readByMembers = [];
        room.getMembers().forEach((member) => {
            const userIdForMember = member?.user?.userId;
            let groupMemberReadStatus = new Object();
            groupMemberReadStatus.user_id = userIdForMember;
            groupMemberReadStatus.name=member?.name;

            if (this.hasEventBeenReadByUser(room, messageReceived.event_id, userIdForMember)){
                groupMemberReadStatus.hasRead = true;
            } else {
                groupMemberReadStatus.hasRead = false;
            }

            readByMembers.push(groupMemberReadStatus);

        });
        messageReceived.readByMembers=readByMembers;     
        console.log("578======", messageReceived, this.roomLastPageLoadedMap);
        
   //     console.log("405======tempAllRoom ", this.allRooms);
   //     console.log("406====", currentRoom, room, room.roomId);

        const isThisRoomOnLastPage = this.roomLastPageLoadedMap.get(room.roomId);
   //     console.log("409------======room.room_id====", room.roomId);
   //     console.log("410------======this.roomLastPageLoadedMap====", this.roomLastPageLoadedMap);
        if (isThisRoomOnLastPage) {
            if (currentRoom.roomId == room.roomId) {
                //found it
                let newCurrentRoomMessages = [];
                if (currentRoom.messages.length) {
                    newCurrentRoomMessages = [...currentRoom.messages]; //clone array
                }
           //     console.log("418====", currentRoom.messages);
                currentRoom.messages.push(messageReceived);
                this.eventMap.set(messageReceived.event_id, messageReceived);

                currentRoom.typing = false;
                currentRoom.lastMessageReceived = messageReceived;


           //     console.log("426======messageReceived ", messageReceived);
           //     console.log("427======tempAllRoom ", this.allRooms);
                // break;
            }
            this.allRooms = this.sortAndRemoveDuplicatesFromAllRoomsArray();
            this.allRooms.sort(this.comparator);

        } else {
            let currentRoom = this.roomMap.get(messageReceived.room_id);
       //     console.log("434------======currentRoom====", currentRoom);
       //     console.log("434------======messageReceived.room_id====", messageReceived.room_id);

            // currentRoom.lastMessageReceived = messageReceived;
        }
   //     console.log("439======", this.allRooms);
        this.emit('message', {
            allRooms: this.allRooms,
        });
    }

    isInitialSyncComplete() {

        return this.client?.isInitialSyncComplete();

    }
    
    createCall(roomId) {
        return matrixcs.createNewMatrixCall(this.client, roomId);
    }
    createGroupCall(roomId) {
        // var confCall = new ConferenceCall(
        //     this.client, roomId
        // );
        return this.setup((call, roomId) => {
            this.client.placeCall(call);
        });      
    }    

    async createRoom(options) {
        try {
       //     console.log("592=====options", options);
            let invite = [];
            
            options.invite.forEach((user) => {
                // let userM = new Object();
                // userM.user_id = user.matrix_user_id;
                if (user.matrix_user_id  !== this.userId){
                    invite.push(user.matrix_user_id);
                }
            });
            options.invite=invite;
            const createdRoom = await this.client.createRoom(options);
       //     console.log("600====", createdRoom);
            const room = await this._waitForRoom(createdRoom.room_id, 'createRoom');
       //     console.log("601====", room);
            let localRoom = new Object();
            localRoom.myUserId = room.myUserId;
            localRoom.name = options.name;
            localRoom.roomId = room.roomId;
            // localRoom.eventReadUpTo = room.getPendingEvents();
            localRoom.members = room.currentState.members;
            let localRoomMessages = [];

            let tl = new TimelineWindow(matrixcs.client, room, {windowLimit: 1000});
            await tl.load(null,40);

            for (const t of tl.getEvents()){
           //     console.log("617====", t);
                if (t.event.type=="m.room.create"){
               //     console.log("619====", t);
                }
                if (messageTypes.includes(t.event.type)) {
                    this.processMessageLoaded(t, room, localRoomMessages, localRoom);                        
                }
            // });
            }            
            localRoom.messages = localRoomMessages;
            this.roomMap.set(localRoom.roomId, localRoom);
            this.matrixRoomMap.set(localRoom.roomId, room);
    
            this.allRooms.push(localRoom);
            this.allRooms = this.sortAndRemoveDuplicatesFromAllRoomsArray();
            this.allRooms.sort(this.comparator);
            this.roomLastPageLoadedMap.set(localRoom.roomId, true);

            
            this.emit('createRoom', {
                allRooms: this.allRooms,
            });
            return room;
        } catch (e) {
            throw e;
        }
    }
    async setTypingIndicatorForRoom(roomId, userId, status) {

        if (!status || status==undefined){
            status = true;
        } else {
            status = false;
            alert("hello");
        }
        // let url = `${this.homeserver}/_matrix/client/r0/rooms/${roomId}/typing/${userId}`
        let url = `https://reverseproxy.aspiretechnologies.dk/_matrix/client/r0/rooms/${roomId}/typing/${userId}`
        const config = {
            headers: { Authorization: `Bearer ${this.accessToken}` }
        };

        const bodyParameters = {
            "typing": status,
            "timeout": 500
        };
        this.client.sendTyping(roomId, true, 500);
        // public sendTyping(roomId: string, isTyping: boolean, timeoutMs: number): Promise<{}> {

        // axios.put(
        //     url,
        //     bodyParameters,
        //     config
        // ).then(console.log).catch(console.log);
    }
    _makeRoomEventPromise(roomId, caller) {
        let timeoutId;
        let roomCallback;
        const promise = new Promise((resolve, reject) => {
            roomCallback = (room) => {
                if (room && room.roomId === roomId) {
                    this._debugLog('JS SDK received Room event:', room);
                    clearTimeout(timeoutId);
                    this.client.removeListener('Room', roomCallback);
                    resolve(room);
                }
            };
            timeoutId = setTimeout(() => {
                this.client.removeListener('Room', roomCallback);
                reject(new Error(`Timed out waiting for sync event for ${caller} ` +
                    `room ${roomId}`));
            }, 30 * 1000); // 30 seconds because maybe matrix.org is struggling
            this.client.on('Room', roomCallback);
        });
        return {
            promise,
            timeoutId,
            roomCallback,
        };
    }

    _makeRoomStateEventPromise(roomAlias, caller) {
        let timeoutId;
        let roomStateCallback;
        const promise = new Promise((resolve, reject) => {
            roomStateCallback = (event, state) => {
                const events = state.getStateEvents('m.room.aliases');
                if (!events) {
                    return;
                }
                events.map((event) => {
                    if (!event) {
                        return;
                    }
                    event.getContent().aliases.map((alias) => {
                        if (alias === roomAlias) {
                            // room exists and we are joined
                            this._debugLog(`JS SDK (${caller}) received alias`, alias);
                            clearTimeout(timeoutId);
                            this.client.removeListener('RoomState.events',
                                roomStateCallback);
                            resolve(true);
                        }
                    });
                });
            };
            timeoutId = setTimeout(() => {
                this.client.removeListener('RoomState.events', roomStateCallback);
                reject(new Error(`Timed out waiting for sync event for ${caller} ` +
                    `room ${roomAlias}`));
            }, 30 * 1000); // 30 seconds because maybe matrix.org is struggling
            this.client.on('RoomState.events', roomStateCallback);
        });
        return {
            promise,
            timeoutId,
            roomStateCallback,
        };
    }

    async _waitForRoom(roomId, caller) {
        try {
            let room = this.client.getRoom(roomId);
            if (!room) {
                const roomEvent = this._makeRoomEventPromise(roomId, caller);
                room = await roomEvent.promise;
            }
            if (!room) {
                throw new Error(`JS SDK (${caller}) did not receive Room event` +
                    'and so does not have the room');
            }
            return room;
        } catch (e) {
            throw e;
        }
    }

    async _waitForRoomAndAliases(caller, roomAlias, roomId) {
        try {
            const room = await this._waitForRoom(roomId, caller);
            let hasAlias = room.getAliases().find((alias) => alias === roomAlias);
            if (!hasAlias) {
                const roomStateEvent = this._makeRoomStateEventPromise(roomAlias, caller);
                hasAlias = await roomStateEvent.promise;
            }
            if (!hasAlias) {
                throw new Error(`JS SDK (${caller}) did not receive aliases for room`);
            }
            this._debugLog('JS SDK joined', roomAlias, ':', room);
            // room exists and we are joined
            this._debugLog(`${this.userId} joined ${roomAlias} (${caller})`);
            return room;
        } catch (e) {
            throw e;
        }
    }

    async joinRoomWithAlias(roomAlias, createOptions) {
        try {
            this._debugLog(`Attempting to join room ${roomAlias}`);
            const joinedRoom = await this.client.joinRoom(roomAlias);
            const room = await this._waitForRoomAndAliases('joinRoom',
                roomAlias, joinedRoom.roomId);
            return room;
        } catch (e) {
            if (e.errcode === 'M_NOT_FOUND') {
                this._debugLog(`IGNORE THE 404 - Attempting to create room ${roomAlias}`);
                // room does not exist
                const createdRoom = await this.client.createRoom(createOptions);
                const room = await this._waitForRoomAndAliases('createRoom',
                    roomAlias, createdRoom.room_id);
                return room;
            } else {
                throw e;
            }
        }
    }

    async joinRoomWithId(roomId) {
        this._debugLog(`Attempting to join room ${roomId}`);
        try {
            const joinedRoom = await this.client.joinRoom(roomId);
            const room = await this._waitForRoom(joinedRoom.roomId, 'joinRoom');
            return room;
        } catch (e) {
            throw e;
        }
    }
    async inviteUserToRoomWithId(roomId, user) {
        this._debugLog(`Attempting to join room ${roomId}`);
        try {
            const member2 = new RoomMember(roomId, user);
            member2.membership = "invite";
            return true;
        } catch (e) {
            throw e;
        }
    }
    async inviteUserListToRoomWithId(roomId, users) {
   //     console.log("inviteUserListToRoomWithId",roomId, users)
        this._debugLog(`Attempting to join room ${roomId}`);
        try {
            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                const member2 = new RoomMember(roomId, user);
           //     console.log("inviteUserListToRoomWithIdmem",member2)

                member2.membership = "invite";
                this.client.invite(roomId, user);
            }
            return true;
        } catch (e) {
            throw e;
        }
    }

    async leaveRoom(roomId) {
        // let url = `https://reverseproxy.aspiretechnologies.dk/_matrix/federation/v1/make_leave/${roomId}/${this.userId}`;
        // let url = `https://devm.vjoinlife.com/_matrix/client/r0/rooms/${roomId}/leave`;
        
        // // let url = `https://reverseproxy.aspiretechnologies.dk/_matrix/client/r0/rooms/${room_id}/read_markers`;
        // const config = {
        //     headers: { Authorization: `Bearer ${this.accessToken}` }
        // };



        // await axios.post(
        //     url,
        //     config
        // );

         this.client.leave(roomId);
         const x = this.allRooms.filter((value, index, arr) => {
       //     console.log("856====", value, roomId);
            if (value.roomId == roomId) {
                // Removes the value from the original array
           //     console.log("861====", value, roomId);

                    arr.splice(index, 1);
                    return true;
                }
                return false;            
         });   
         this.allRooms = this.sortAndRemoveDuplicatesFromAllRoomsArray();
         this.allRooms.sort(this.comparator);
         this.emit('syncComplete', {
             allRooms: this.allRooms,
         });
         return;
    }
    kickUser(room, user, callbackFunction) {
        return this.client.kick(room.roomId, user, callbackFunction);
    }
    getDisplayNameForUser(userId) {
        const user = this.client.getUser(userId);
        return user && user.displayName ? user.displayName : userId;
    }

    getJoinedMembers(roomId) {
        const room = this.client.getRoom(roomId);
        if (!room) {
            this._debugLog(`Room ${roomId} not found`);
            return [];
        }

        return room.getJoinedMembers()
            .map((m) => m.userId)
            .filter((m) => m !== this.userId);
    }
    comparator = (a, b) => {

        let aMessages = a.messages;
        if (isNaN(aMessages?.length)) {
            return 0;
        }
        let aMessagesLastElement = aMessages[aMessages?.length - 1];
        let aMessageLastMessageTimeStamp = 0;
        if (aMessages.length > 0) {
            aMessageLastMessageTimeStamp = aMessagesLastElement.origin_server_ts;
        }
        let bMessages = b.messages;
        let bMessagesLastElement;
        let bMessageLastMessageTimeStamp = 0;
        if (bMessages) {
            let bMessagesLastElement = bMessages[bMessages.length - 1];

            if (bMessages.length > 0) {
                bMessageLastMessageTimeStamp = bMessagesLastElement.origin_server_ts;
            }

        }
        return bMessageLastMessageTimeStamp - aMessageLastMessageTimeStamp;

    }
    newComparator (a, b) {
        // < 0 = a comes first (lower index) - we want high indexes = newer
        var aMsg = a.messages[a.messages.length - 1];
        if (!aMsg) {
            return -1;
        }
        var bMsg = b.messages[b.messages.length - 1];
        if (!bMsg) {
            return 1;
        }
        if (aMsg.getTs() > bMsg.getTs()) {
            return 1;
        } else if (aMsg.getTs() < bMsg.getTs()) {
            return -1;
        }
        return 0;
    }
    reverseComparator = (a, b) => {
        let aMessages = a;
        let aMessagesLastElement = aMessages[aMessages.length - 1];
        let aMessageLastMessageTimeStamp = 0;
        if (aMessages.length > 0) {
            aMessageLastMessageTimeStamp = aMessagesLastElement.origin_server_ts;
        }
        let bMessages = b;
        let bMessagesLastElement;
        let bMessageLastMessageTimeStamp = 0;
        if (bMessages) {
            let bMessagesLastElement = bMessages[bMessages.length - 1];

            if (bMessages.length > 0) {
                bMessageLastMessageTimeStamp = bMessagesLastElement.origin_server_ts;
            }

        }
        return aMessageLastMessageTimeStamp - bMessageLastMessageTimeStamp;

    }

    async sendTextMessage(content, room, isForwarded) {
        try {
            if (isForwarded) {
           //     console.log("yespresenet")
                content.isForwarded = isForwarded
            }

       //     console.log("yespresenet1",content,room)

            await this.client.sendEvent(room.roomId, "m.room.message", content, "", (err, res) => {
           //     console.log(err);
            });
            await this.client.sendTyping(room.roomId, false);

            // this.setTypingIndicatorForRoom(room.roomId, this.userId, false);
        } catch (e){
       //     console.log(e)
        }


    }
    sendLocation(geo_uri, room, imageUrl, locationUrl, isForwarded) {
        // alert("540"+geo_uri)
        // alert("541"+JSON.stringify(room))
        // alert("542"+imageUrl)
        // alert("543"+locationUrl)

        var content = {
            "body": "Shared location",
            "geo_uri": geo_uri,
            "locationUrl": locationUrl,
            "imageUrl": imageUrl,
            "info": {
                "thumbnail_info": {
                    "h": 300,
                    "mimetype": "image/jpeg",
                    "size": 46144,
                    "w": 300
                },
                "thumbnail_url": "https://www.dropbox.com/s/3jjt3t6p5taahpu/IMG_0968.heic?dl=0"
            },
            "msgtype": "m.location"
        };
        if (isForwarded) {
            content.isForwarded = isForwarded
        }

        // alert("569 = "+JSON.stringify(content))
        // this.sendTextMessage(content, room);
        this.client.sendEvent(room.roomId, "m.room.message", content, "", (err, res) => {
       //     console.log(err);
        });

    }
    forwardLocation(content, roomId) {
   //     console.log("isForwardedCase",content,roomId)
        // alert("540"+geo_uri)
        // alert("541"+JSON.stringify(room))
        // alert("542"+imageUrl)
        // alert("543"+locationUrl)

        content.isForwarded = "isForwarded";

        // alert("569 = "+JSON.stringify(content))
        // this.sendTextMessage(content, room);
        this.client.sendEvent(roomId, "m.room.message", content, "", (err, res) => {
       //     console.log(err);
        });

    }

    async matrixUploadFile(room, file, thumbnailFile, isForwarded) {
        try {
            const image_exts = ['jpg', 'gif', 'png', 'bmp', 'tiff', 'jpeg', 'webp']
            const video_exts = ['mp4', 'avi', 'mkv', 'rmvb', 'rm', 'wmv', 'webm']
            const audio_exts = ['mp3', 'wav', 'ac3', 'wma', 'aac', 'oga']
            var msgtype = "m.file";
            let fileExtension = file.name.split('.')[1];

            if (image_exts.indexOf(fileExtension) >= 0) {
                msgtype = "m.image";
            }
            else if (video_exts.indexOf(fileExtension) >= 0) {
                msgtype = "m.video";
            }
            else if (audio_exts.indexOf(fileExtension) >= 0) {
                msgtype = "m.audio";
            }

            const uploadResponse = await this.client.uploadContent(file, {
                rawResponse: false,
                rawResponse: false,
                onlyContentUri: false,
                type: file.type
            });
            const matrixUrl = uploadResponse.content_uri;
       //     console.log(matrixUrl);
            var content = {
                msgtype: msgtype,
                body: file.name,
                info: {
                    h: 398,
                    mimetype: file.type,
                    size: 31037,
                    w: 394,
                },
                url: matrixUrl
            };

            if (thumbnailFile) {
                // alert("thumbnailFile");
                const uploadResponseThumbnail = await this.client.uploadContent(thumbnailFile, {
                    rawResponse: false,
                    rawResponse: false,
                    rawResponse: false,
                    onlyContentUri: false,
                    type: file.type
                });
                const matrixUrlThumbnailUrl = uploadResponseThumbnail.content_uri;
                const thumbnail_info = {
                    h: 300,
                    mimetype: thumbnailFile.type,
                    size: 46144,
                    w: 300
                };

                content.info.thumbnail_info = thumbnail_info;
                content.info.thumbnail_url = matrixUrlThumbnailUrl;
            }
            if (isForwarded) {
                content.isForwarded = isForwarded
            }
            this.client.sendEvent(room.roomId, "m.room.message", content, "", (err, res) => {
           //     console.log(err);
            });
        } catch (error) {
       //     console.log(error);

        }
    }
    async fowardMatrixUploadFile(room, fileUrl, thumbnailFileUrl, msgtype) {
   //     console.log("clientcallforward",room, fileUrl, thumbnailFileUrl, msgtype)
        try {
            var content = {
                msgtype: msgtype,
                body: fileUrl,
                info: {
                    h: 398,
                    mimetype: msgtype,
                    size: 31037,
                    w: 394,
                },
                url: fileUrl
            };

            if (thumbnailFileUrl) {
                // alert("thumbnailFile");
                const thumbnail_info = {
                    h: 300,
                    mimetype: msgtype,
                    size: 46144,
                    w: 300
                };

                content.info.thumbnail_info = thumbnail_info;
                content.info.thumbnail_url = thumbnailFileUrl;
            }
            content.isForwarded = "isForwarded"
            this.client.sendEvent(room, "m.room.message", content, "", (err, res) => {
           //     console.log(err);
            });
        } catch (error) {
       //     console.log(error);

        }
    }
 
    replyMessageSendLocation(roomId, chatMessage, geo_uri, imageUrl, locationUrl) {

        var content = {
            "body": "Shared location",
            "geo_uri": geo_uri,
            "locationUrl": locationUrl,
            "imageUrl": imageUrl,
            "info": {
                "thumbnail_info": {
                    "h": 300,
                    "mimetype": "image/jpeg",
                    "size": 46144,
                    "w": 300
                },
                "thumbnail_url": "https://www.dropbox.com/s/3jjt3t6p5taahpu/IMG_0968.heic?dl=0"
            },
            "msgtype": "m.location",
            "m.relates_to": {
                "m.in_reply_to": {
                    "event_id": chatMessage.roomId.event_id,
                }
            },
            "m_relates_to": {
                "m_in_reply_to": {
                    "event_id": chatMessage.roomId.event_id,
                }
            },
        };

        this.client.sendEvent(roomId, "m.room.message", content, "", (err, res) => {
       //     console.log(err);
        });

    }

    async replyMessageUploadFile(roomId, chatMessage, file) {
        try {
            const image_exts = ['jpg', 'gif', 'png', 'bmp', 'tiff', 'jpeg', 'webp']
            const video_exts = ['mp4', 'avi', 'mkv', 'rmvb', 'rm', 'wmv', 'webm']
            const audio_exts = ['mp3', 'wav', 'ac3', 'wma', 'aac', 'oga']
            var msgtype = "m.file";
            let fileExtension = file.name.split('.')[1];

            if (image_exts.indexOf(fileExtension) >= 0) {
                msgtype = "m.image";
            }
            else if (video_exts.indexOf(fileExtension) >= 0) {
                msgtype = "m.video";
            }
            else if (audio_exts.indexOf(fileExtension) >= 0) {
                msgtype = "m.audio";
            }

            const uploadResponse = await this.client.uploadContent(file, {
                rawResponse: false,
                rawResponse: false,
                onlyContentUri: false,
                type: file.type
            });
            const matrixUrl = uploadResponse.content_uri;
       //     console.log(matrixUrl);

            var content = {
                msgtype: msgtype,
                body: file.name,
                info: {
                    h: 398,
                    mimetype: file.type,
                    size: 31037,
                    w: 394
                },
                url: matrixUrl,
                "m.relates_to": {
                    "m.in_reply_to": {
                        "event_id": chatMessage.roomId.event_id,
                    }
                },
                "m_relates_to": {
                    "m_in_reply_to": {
                        "event_id": chatMessage.roomId.event_id,
                    }
                },
            };

            this.client.sendEvent(roomId, "m.room.message", content, "", (err, res) => {
           //     console.log(err);
            });
        } catch (error) {
       //     console.log(error);

        }
    }
    replyMessage(roomId, chatMessage, body) {
        try {

            var content = {
                "msgtype": 'm.text',
                "body": body,
                "m.relates_to": {
                    "m.in_reply_to": {
                        "event_id": chatMessage.roomId.event_id,
                    }
                },
                "m_relates_to": {
                    "m_in_reply_to": {
                        "event_id": chatMessage.roomId.event_id,
                    }
                },
            };
            // other fields as required by events
       //     console.log("replyMessage sd contentk====", chatMessage.roomId.event_id);
       //     console.log("replyMessage sd roomId====", roomId);
            this.client.sendEvent(roomId, "m.room.message", content, "", (err, res) => {
           //     console.log(err);
            });
        } catch (error) {
       //     console.log(error);

        }
    }
    
    async sendReaction(userId, chatMessage, key) {
        try {
            var content = {
                "msgtype": 'm.reaction',
                "m.relates_to": {
                    "rel_type": "m.annotation",
                    "event_id": chatMessage.roomId.event_id,
                    "key": key
                    
                },
                
            };
       //     console.log("sendReaction sd contentk====", userId);
       //     console.log("sendReaction sd roomId====", chatMessage.roomId.room_id);
            let url = `https://reverseproxy.aspiretechnologies.dk/_matrix/client/r0/rooms/${chatMessage.roomId.room_id}/send/m.reaction/${userId}`
            const config = {
                headers: { Authorization: `Bearer ${this.accessToken}` }
            };
            await this.client.sendEvent(chatMessage.roomId.room_id, 'm.reaction', content);
            
        } catch (error) {
       //     console.log(error);

        }
    }

    createAudioCall(room) {
        try {
            var content = {
                msgtypeKuumba: 'kuumba.audiocall',
                msgtype: 'kuumba.audiocall',
                body: 'Start Audio Call',
            };
            this.client.sendEvent(room.roomId, "m.room.message", content, "", (err, res) => {
           //     console.log(err);
            });
        } catch (error) {
       //     console.log(error);

        }
    }
    cancelAudioCall(roomId, userId) {
        try {
            var content = {
                msgtypeKuumba: 'kuumba.cancel.audiocall',
                msgtype: 'kuumba.cancel.audiocall',
                body: userId + ' canceled Audio Call',
                userId: userId,



            };
            this.client.sendEvent(roomId, "m.room.message", content, "", (err, res) => {
           //     console.log(err);
            });
        } catch (error) {
       //     console.log(error);

        }
    }
    timeoutAudioCall(roomId, userId) {
        try {
            var content = {
                msgtypeKuumba: 'kuumba.timeout.audiocall',
                msgtype: 'kuumba.timeout.audiocall',
                body: userId + ' timedout Audio Call',
                userId: userId,
            };
            this.client.sendEvent(roomId, "m.room.message", content, "", (err, res) => {
           //     console.log(err);
            });
        } catch (error) {
       //     console.log(error);

        }
    }
    cancelVideoCall(roomId, userId) {
        try {
            var content = {
                msgtypeKuumba: 'kuumba.cancel.videocall',
                msgtype: 'kuumba.cancel.videocall',
                body: userId + ' canceled Video Call',
                userId: userId,

            };
            this.client.sendEvent(roomId, "m.room.message", content, "", (err, res) => {
           //     console.log(err);
            });
        } catch (error) {
       //     console.log(error);

        }
    }
    timeoutVideoCall(roomId, userId) {
        try {
            var content = {
                msgtypeKuumba: 'kuumba.timeout.videocall',
                msgtype: 'kuumba.timeout.videocall',
                body: userId + ' timedout Video Call',
                userId: userId,
            };
            this.client.sendEvent(roomId, "m.room.message", content, "", (err, res) => {
           //     console.log(err);
            });
        } catch (error) {
       //     console.log(error);

        }
    }

    stopAudioCall(room, participant, eventId, numberOfParticipants) {

        try {
            let tempAllRoom = [];
            let found = false;
            if (this.allRooms) {
                tempAllRoom = [...this.allRooms];
            }
            for (var i = 0; i < tempAllRoom.length; i++) {
                const currentRoom = tempAllRoom[i];
                if (currentRoom.roomId == room.roomId) {
                    //found it
                    let newCurrentRoomMessages = currentRoom.messages;
                    for (var j = 0; j < newCurrentRoomMessages.length; j++) {
                        const currentMessage = newCurrentRoomMessages[j];
                        if (currentMessage.event_id == eventId) {
                            //found it
                            const newCurrentRoomMessage = Object.assign({}, currentMessage);
                            newCurrentRoomMessage.isInVideoCall = true;
                            newCurrentRoomMessages[j] = newCurrentRoomMessage;
                            found = true;
                            currentRoom.messages = newCurrentRoomMessages;
                            break;
                        }
                    }
                    if (found) {
                        break;
                    }
                }
            }
            // allRooms = [...tempAllRoom];
          //  // console.log("desktop tempAllRoom ====", JSON.stringify(tempAllRoom));
            tempAllRoom.sort(this.comparator);
       //     console.log("674=====stop Audio Call=====",tempAllRoom)
            this.allRooms = tempAllRoom;

            var content = {
                msgtype: 'kuumba.audiocall.stop',
                body: participant + ' has left Rejoin Audio Call?',
            };


            this.client.sendEvent(room.roomId, "m.room.message", content, "", (err, res) => {
           //     console.log(err);
            });
            this.emit('stopVideoCall', {
                allRooms: this.allRooms,
            });

        } catch (error) {
       //     console.log(error);

        }
    }

    createVideoCall(room,text) {
        try {
            var content = {
                msgtypeKuumba: 'kuumba.videocall',
                // msgtype: "kuumba.videocall",
                msgtype: text,

                body: 'Start Video Call',
            };
            this.client.sendEvent(room.roomId, "m.room.message", content, "", (err, res) => {
           //     console.log(err);
            });
        } catch (error) {
       //     console.log(error);

        }
    }
    stopVideoCall(roomId, participant, eventId, numberOfParticipants) {

        try {
            let tempAllRoom = [];
            let found = false;
            if (this.allRooms) {
                tempAllRoom = [...this.allRooms];
            }
            for (var i = 0; i < tempAllRoom.length; i++) {
                const currentRoom = tempAllRoom[i];
                if (currentRoom.roomId == roomId) {
                    //found it
                    let newCurrentRoomMessages = currentRoom.messages;
                    for (var j = 0; j < newCurrentRoomMessages.length; j++) {
                        const currentMessage = newCurrentRoomMessages[j];
                        if (currentMessage.event_id == eventId) {
                            //found it
                            const newCurrentRoomMessage = Object.assign({}, currentMessage);
                            newCurrentRoomMessage.isInVideoCall = true;
                            newCurrentRoomMessages[j] = newCurrentRoomMessage;
                            found = true;
                            currentRoom.messages = newCurrentRoomMessages;
                            break;
                        }
                    }
                    if (found) {
                        break;
                    }
                }
            }
            // allRooms = [...tempAllRoom];
          //  // console.log("desktop tempAllRoom ====", JSON.stringify(tempAllRoom));
            tempAllRoom.sort(this.comparator);
       //     console.log("674=====stop Video Call=====",tempAllRoom)
            this.allRooms = tempAllRoom;

            var content = {
                msgtype: 'kuumba.videocall.stop',
                body: participant + ' has left Rejoin Video Call?',
            };


            this.client.sendEvent(roomId, "m.room.message", content, "", (err, res) => {
           //     console.log(err);
            });
            this.emit('stopVideoCall', {
                allRooms: this.allRooms,
            });

        } catch (error) {
       //     console.log(error);

        }
    }

    async getRooms() {
        let allRooms = [];
   //     console.log("1315 getRooms ======= " );

        this.client.store.getRooms().forEach(async (room) => {
            await this.populateRoom(room, allRooms);
        });
   //     console.log("1397 local all rooms ======= " ,allRooms);
        allRooms.sort(this.comparator);
        this.allRooms = allRooms;
        return allRooms;
    }
    async populateRoom(room, allRooms) {
        let tl = new TimelineWindow(matrixcs.client, room, { windowLimit: 1000 });
        await tl.load(null, 40);

        let canPaginate = tl.canPaginate(EventTimeline.BACKWARDS);
   //     console.log("1270=====", canPaginate);
   //     console.log("1271======b4 paginate", tl.getEvents());
        this.roomLastPageLoadedMap.set(room.roomId, true);

        let localRoom = new Object();
        localRoom.myUserId = room.myUserId;
        localRoom.name = room.name;
        localRoom.roomId = room.roomId;
        // localRoom.eventReadUpTo = room.getPendingEvents();
        localRoom.members = room.currentState.members;
        let localRoomMessages = [];
        // this.client.store.getRoom(room.roomId).timeline.forEach(t => {
        for (const t of tl.getEvents()) {
       //     console.log("1337====", t);
            if (t.event.type == "m.room.create") {
           //     console.log("1453====", t);
            }
            // alert("545===="+JSON.stringify(t))
            if (messageTypes.includes(t.event.type)) {
                this.processMessageLoaded(t, room, localRoomMessages, localRoom);
            }
            localRoom.messages = localRoomMessages;
            this.roomMap.set(localRoom.roomId, localRoom);
            this.matrixRoomMap.set(localRoom.roomId, room);
            // });
        }
   //     console.log("1559====", allRooms);
        allRooms.push(localRoom);
    }

    getPreviousPage(room_id, event_id){
   //     console.log("1403====getPreviousPage",room_id,event_id);
  
   //     console.log("1405=====this.roomMap",this.roomMap);
   //     console.log("1406=====this.roomMap.get(room_id)",this.roomMap.get(room_id));
        let room = this.roomMap.get(room_id);

        let matrixRoom = this.matrixRoomMap.get(room_id);

   //     console.log("1403=====room",room);
      //  // console.log("1404=====room.messages",room.messages);
        if(room==undefined)
            return;
        let messagesArray = [...room?.messages];
   //     console.log("1406=====messagesArray",messagesArray);
        let tl = new TimelineWindow(matrixcs.client, matrixRoom, {windowLimit: 5000});
        tl.load(event_id,40);
        // tl.load(null,100);
   //     console.log("1405======", tl.getEvents());
        let localRoomMessages = [];

        for (const t of tl.getEvents()){
       //     console.log("1324====", t);
       //     console.log("1325====", t.event.type);
            // alert("545===="+JSON.stringify(t))
            if (messageTypes.includes(t.event.type)) {
                this.processMessageLoaded(t, matrixRoom, localRoomMessages, room);                        
            }
        }
   //     console.log("1479======", localRoomMessages);
        if (!room.ctArray || room.ctArray.length < 1) {
            room.ctArray = [];
        }
   //     console.log("1483======", room.messages);
        let ctArray = [...localRoomMessages, ...room.messages];

   //     console.log("1485======", ctArray);

        let uniqueArray = [];
        let hashMapEvents = new Map();
        ctArray.forEach((c) => {
       //     console.log("1518====", c);
       //     console.log("1519====", c.event_id);
       //     console.log("1520====", hashMapEvents.get(c.event_id));
            if (!hashMapEvents.get(c.event_id)) {
           //     console.log("1520====", c);
                uniqueArray.push(c);
                hashMapEvents.set(c.event_id, c);
            }
        });   

   //     console.log("1522=====", uniqueArray);
   //     console.log("1523=====", ctArray);
        room.ctArray = uniqueArray;
        this.roomLastPageLoadedMap.set(room.roomId, true);
   //     console.log("1491=====", room.ctArray);
        room.messages = uniqueArray;
        // room.messages.sort(this.reverseComparator);
   //     console.log("1493=====", room.messages);
   //     console.log("1454=====", this.allRooms);

        this.emit('message', {
            allRooms: this.allRooms,
        });                        
    }
    getNextPage(room_id, event_id){

   //     console.log("1480====getNextPage",room_id,event_id);
   //     console.log("1481====getNextPage---room_id---"+room_id);
   //     console.log("1482====getNextPage====event_id===="+event_id);

   //     console.log("1484=====this.roomMap",this.roomMap);
   //     console.log("1485=====this.roomMap.get(room_id)",this.roomMap.get(room_id));
        let room = this.roomMap.get(room_id);
        if (!room || room==undefined) 
            return;
   //     console.log("1487=====room"+room);

        let matrixRoom = this.matrixRoomMap.get(room_id);

   //     console.log("1565=====room",room);
    //     console.log("1557=====this.matrixRoomMap",this.matrixRoomMap.get(room_id));
        if(room==undefined)
            return;
        let messagesArray = [...room?.messages];
   //     console.log("1570=====messagesArray",messagesArray);
        let tl = new TimelineWindow(matrixcs.client, matrixRoom, {windowLimit: 5000});
   //     console.log("1563-------event_id=", event_id)
   //     console.log("1564-------matrixRoom=", matrixRoom)
        try {
            tl.load(event_id,40);
        } catch {
         tl.load(null,100);
        }
   //     console.log("1574======", tl.getEvents());
        let localRoomMessages = [];

        for (const t of tl.getEvents()){
       //     console.log("1578====", t);
       //     console.log("1579====", t.event.type);
            // alert("545===="+JSON.stringify(t))
            if (messageTypes.includes(t.event.type)) {
                this.processMessageLoaded(t, matrixRoom, localRoomMessages, room);                        
            }
        }
   //     console.log("1631======", localRoomMessages);
        if (!room.ctArray || room.ctArray.length < 1) {
            room.ctArray = [];
        }
   //     console.log("1635======", room.messages);
        let ctArray = [];
        console.log("1689====", tl.getEvents());
        if ((tl.getEvents()[39] && tl.getEvents()[39].event.event_id==event_id) || tl?.getEvents()?.length < 40){
            this.roomLastPageLoadedMap.set(room.roomId, true);
            ctArray =  room.messages;
        } else {
            console.log("1693 this.roomLastPageLoadedMap.set false");
            this.roomLastPageLoadedMap.set(room.roomId, false);
            ctArray = [...room.messages, ...localRoomMessages];
        }

   //     console.log("1638======", ctArray);

        let uniqueArray = [];
        let hashMapEvents = new Map();
        ctArray.forEach((c) => {
       //     console.log("1643====", c);
       //     console.log("1644====", c.event_id);
       //     console.log("1645====", hashMapEvents.get(c.event_id));
            if (!hashMapEvents.get(c.event_id)) {
           //     console.log("1647====", c);
                uniqueArray.push(c);
                hashMapEvents.set(c.event_id, c);
            }
        });   

   //     console.log("1653=====", uniqueArray);
   //     console.log("1654=====", ctArray);

        room.ctArray = uniqueArray;
   //     console.log("1657=====", room.ctArray);
        room.messages = uniqueArray;

   //     console.log("1660=====", room.messages);

            
   //     console.log("1601=====", this.allRooms);

        this.emit('message', {
            allRooms: this.allRooms,
        });                        


    }   

    processMessageLoaded(t, matrixRoom, localRoomMessages, localRoom) {
   //     console.log("1582====", t.event.content);
        let message = new Object();
        message.content = t.event.content;
        message.event_id = t.event.event_id;
        message.event = t.event;

        message.origin_server_ts = t.event.origin_server_ts;
        message.room_id = t.event.room_id;
        message.sender = t.event.sender;
        matrixRoom.getMembers().forEach((member) => {

            if (member.user?.userId == message.sender ){
                message.sender_name =  member?.name;
            }

        });


        message.isRead = this.hasUserReadEvent(matrixRoom, message.event_id);
        let readByMembers = [];
        matrixRoom.getMembers().forEach((member) => {
       //     console.log("1596====member", member)
            const userIdForMember = member?.user?.userId;
            let groupMemberReadStatus = new Object();
            groupMemberReadStatus.user_id = userIdForMember;
            groupMemberReadStatus.name=member?.name;

            if (this.hasEventBeenReadByUser(matrixRoom, message.event_id, userIdForMember)){
                groupMemberReadStatus.hasRead = true;
            } else {
                groupMemberReadStatus.hasRead = false;
            }

            readByMembers.push(groupMemberReadStatus);

        });
        message.readByMembers=readByMembers;

        if (message.sender == this.userId) {
            message.receivedOrSent = SENT;
        } else {
            message.receivedOrSent = RECEIVED;
        }
        message.type = t.event.type;
        this.eventMap.set(message.event_id, message);
        localRoomMessages.push(message);
        localRoom.lastMessageReceived = message;
        if (message && t.event.type == 'm.reaction') {
       //     console.log("1602======", message.content);
            if (message.content['m.relates_to']) {
           //     console.log("1604======", message.content['m.relates_to'].event_id);
                let originalEvent = this.eventMap.get(message.content['m.relates_to'].event_id);
           //     console.log("1606======", originalEvent);
                localRoom.lastMessageReceived.originalEvent=originalEvent;

                let reactions = originalEvent?.reactions;
           //     console.log("1185======", reactions);

                if (!reactions || reactions.length < 1) {
                    reactions = [];
                }
              //  // console.log("1614======", reactions.push(message));
                var reactionAlreadyExist = reactions.find(function(arrayMessage, index) {
                    if(message.sender == arrayMessage.sender && message.content?.["m.relates_to"]?.key == arrayMessage.content?.["m.relates_to"]?.key)
                        return true;
                });
           //     console.log("1696 reactionAlreadyExist===", reactionAlreadyExist)
                if (!reactionAlreadyExist){

                    reactions.push(message);
                }
                if (originalEvent) {
                    originalEvent.reactions = reactions;

                }
           //     console.log("1625======", originalEvent);
            }
        } 
    }

    gotoEventId(room_id, event_id){

   //     console.log("1649====gotoEventId",room_id,event_id);
   //     console.log("1650====gotoEventId---room_id---"+room_id);
   //     console.log("1651====gotoEventId====event_id===="+event_id);
   //     console.log("1652=====this.roomMap",this.roomMap);
   //     console.log("1653=====this.roomMap.get(room_id)",this.roomMap.get(room_id));
        let room = this.roomMap.get(room_id);

        let matrixRoom = this.matrixRoomMap.get(room_id);

   //     console.log("1658=====room",room);
   //     console.log("1659=====this.matrixRoomMap",this.matrixRoomMap.get(room_id));

        let tl = new TimelineWindow(matrixcs.client, matrixRoom, {windowLimit: 5000});
   //     console.log("1662-------event_id=", event_id)
   //     console.log("1663-------matrixRoom=", matrixRoom)
        try {
            tl.load(event_id,40);
        } catch {
         tl.load(null,40);
        }
   //     console.log("1669======", tl.getEvents());
        let localRoomMessages = [];

        for (const t of tl.getEvents()){
       //     console.log("1673====", t);
       //     console.log("1674====", t.event.type);
            if (messageTypes.includes(t.event.type)) {
           //     console.log("1676====", t.event.content);
                let message = new Object();
                message.content = t.event.content;
                message.event_id = t.event.event_id;
                message.event = t.event;

                message.origin_server_ts = t.event.origin_server_ts;
                message.room_id = t.event.room_id;
                message.sender = t.event.sender;
                matrixRoom.getMembers().forEach((member) => {

                    if (member.user?.userId == message.sender ){
                        message.sender_name =  member?.name;
                    }
        
                });                
                message.isRead = this.hasUserReadEvent(matrixRoom, message.event_id);

                if (message.sender == this.userId) {
                    message.receivedOrSent = SENT;
                } else {
                    message.receivedOrSent = RECEIVED;
                }
                message.type = t.event.type;
                this.eventMap.set(message.event_id, message);
                localRoomMessages.push(message);
                if (message && t.event.type == 'm.reaction'){
               //     console.log("1696======", message.content)
                    if (message.content['m.relates_to']){
                   //     console.log("1698======", message.content['m.relates_to'].event_id)
                        let originalEvent = this.eventMap.get(message.content['m.relates_to'].event_id);
                   //     console.log("1700======", originalEvent)

                        let reactions = originalEvent?.reactions;
                   //     console.log("1703======", reactions)

                        if (!reactions || reactions.length < 1) {
                            reactions = [];
                        }
                      //  // console.log("1708======", reactions.push(message))

                        var reactionAlreadyExist = reactions.find(function(arrayMessage, index) {
                            if(message.sender == arrayMessage.sender && message.content?.["m.relates_to"]?.key == arrayMessage.content?.["m.relates_to"]?.key)
                                return true;
                        });
                   //     console.log("1775 reactionAlreadyExist===", reactionAlreadyExist)
                        if (!reactionAlreadyExist){
                            reactions.push(message);
                        }
                        
                        if (originalEvent)
                        {
                            originalEvent.reactions = reactions;

                        } 
                   //     console.log("1716======", originalEvent)
                    }
                }                        
            }
        }
   //     console.log("1721======", localRoomMessages);
        if (!room.ctArray || room.ctArray.length < 1) {
            room.ctArray = [];
        }
   //     console.log("1725======", room.messages);
        let ctArray = [];
        console.log("1908 this.roomLastPageLoadedMap.set false");

        this.roomLastPageLoadedMap.set(room.roomId, false);
        ctArray =  room.messages;

   //     console.log("1730======", ctArray);

        room.ctArray = ctArray;
   //     console.log("1733=====", room.ctArray);
        room.messages = ctArray;
   //     console.log("1698=====", room.messages);

        this.emit('message', {
            allRooms: this.allRooms,
        });                        
    }   
    
    async search(term){
        // alert(term)
        const SEARCH_LIMIT = 100;

        const filter = {
            lazy_load_members: true,
            include_redundant_members: true,
            types: ["m.room.message"],
        }      
        const body = {
            search_categories: {
                room_events: {
                    search_term: term,
                    filter: filter,
                    order_by: "recent",
                    // event_context: {
                    //     before_limit: 1,
                    //     after_limit: 1,
                    //     include_profile: true,
                    // },
                },
            },
        };
    

        // search_categories: {
        //     room_events: {
        //         search_term: string;
        //         keys?: SearchKey[];
        //         filter?: IRoomEventFilter;
        //         order_by?: SearchOrderBy;
        //         event_context?: {
        //             before_limit?: number;
        //             after_limit?: number;
        //             include_profile?: boolean;
        //         };
        //         include_state?: boolean;
        //         groupings?: {
        //             group_by: {
        //                 key: GroupKey;
        //             }[];
        //         };
        //     };
        // };     
        
        //filter
        // lazy_load_members?: boolean;
        // include_redundant_members?: boolean;
        // types?: Array<EventType | string>;
        // related_by_senders?: Array<RelationType | string>;
        // related_by_rel_types?: string[];
        // unread_thread_notifications?: boolean;
        // "org.matrix.msc3773.unread_thread_notifications"?: boolean;
    
        // // Unstable values
        // "io.element.relation_senders"?: Array<RelationType | string>;
        // "io.element.relation_types"?: string[];


    
        const searchResult = await this?.client?.search({ body: body });        
        this.emit('search', {
            searchResult: searchResult,
        });          
    }
    hasEventBeenReadByUser(room, event_id, user_id){
   //     console.log("1669===",  room,  event_id, user_id);
        let isRead = false;
        if (room.hasUserReadEvent(user_id, event_id)) {
            isRead = true;      
        }
        return isRead;
    }
    hasUserReadEvent(room, event_id){
   //     console.log("1677===",  room,  event_id);
        let isRead = false;
        if (room.hasUserReadEvent(this.client.getUserId(), event_id)) {
            isRead = true;      
        }
        return isRead;
    }
    sortAndRemoveDuplicatesFromAllRoomsArray(){
        let sortedAndDuplicateRemovedAllRooms = this.allRooms;
        sortedAndDuplicateRemovedAllRooms.forEach((room) => {
            room.messages = this.sortAndRemoveDuplicates(room.messages, 'origin_server_ts');
        })
        return sortedAndDuplicateRemovedAllRooms;
    }
    sortAndRemoveDuplicates(arr, property) {
        // Step 1: Sort the array of objects based on the specified property
        arr.sort((a, b) => {
          if (a[property] < b[property]) return -1;
          if (a[property] > b[property]) return 1;
          return 0;
        });
      
        // Step 2: Remove duplicates based on the specified property using a Map
        const uniqueMap = new Map();
        arr.forEach((item) => uniqueMap.set(item[property], item));
      
        // Step 3: Convert the unique Map values back to an array
        const sortedUniqueArr = Array.from(uniqueMap.values());
      
        return sortedUniqueArr;
      }
      

      
    // const sortedUniqueArrayOfObjects = sortAndRemoveDuplicates(unsortedArrayOfObjects, 'id');
      
    async setReadMarker(room_id, event_id){
       console.log("setReadMarker",room_id,event_id);
        try {
            let matrixRoom = this.client.getRoom(room_id);
            if (!this.hasUserReadEvent(matrixRoom, event_id)){
                let url = `https://reverseproxy.aspiretechnologies.dk/_matrix/client/r0/rooms/${room_id}/read_markers`;
                const config = {
                    headers: { Authorization: `Bearer ${this.accessToken}` }
                };
        
                const bodyParameters = {
                    "m.fully_read": `${event_id}`,
                    "m.read": `${event_id}`
                };
        
                await axios.post(
                    url,
                    bodyParameters,
                    config
                );
                let BreakException = {};
                console.log("2022===", event_id)
                let continueProcessing = true;
                this.allRooms.forEach ((room) => {
                    console.log("2022===", room, event_id)
                    if (continueProcessing){
                        room.messages.forEach((message) => {
                            console.log("2022===message", message, event_id)
                            if (continueProcessing){
                                if (message.event_id == event_id){
                                    message.isRead = true;
                                    console.log("2022===", message);
                                    continueProcessing = false;
                                }
                                message.isRead = true;
                            }
    
                        });
                    }
                });
                console.log("2030===this.allRooms====", this.allRooms);
                this.emit('message', {
                    allRooms: this.allRooms,
                });  
            } 

        } catch (err)
        {
       //     console.log(err)
        }
    }  


    getRoomAliases(roomId) {
        const room = this.client.getRoom(roomId);
        if (!room) {
            this._debugLog(`Room ${roomId} not found`);
            return [];
        }
        return room.getAliases();
    }

    getRoomIdForAlias(alias) {
        return this.client.getRoomIdForAlias(alias).then((roomId) => roomId);
    }

    mxcUrlToHttp(mxcUrl) {
        return this.client.mxcUrlToHttp(mxcUrl);
    }
    setup() {
        var self = this;
        return this._joinConferenceUser().then(function() {
            return self._getConferenceUserRoom();
        }).then(function(room) {
            // return a call for *this* room to be placed. We also tack on
            // confUserId to speed up lookups (else we'd need to loop every room
            // looking for a 1:1 room with this conf user ID!)
            var call = this.client.createNewMatrixCall(self.client, room.roomId);
            call.confUserId = self.confUserId;
            return call;
        });
    }  
    _joinConferenceUser() {
        // Make sure the conference user is in the group chat room
        var groupRoom = Client.moi.client.getRoom(this.groupRoomId);
        if (!groupRoom) {
            return q.reject("Bad group room ID");
        }
        var member = groupRoom.getMember(this.confUserId);
        if (member && member.membership === "join") {
            return q();
        }
        return Client.moi.client.invite(this.groupRoomId, this.confUserId);
    } 
    async setDisplayName(displayName) {
        await Client.moi.client.setDisplayName(displayName);
    } 
    async setAvatarUrl(file) {
    console.log("setAvatarUrl",file)
        try {
            const image_exts = ['jpg', 'gif', 'png', 'bmp', 'tiff', 'jpeg', 'webp']
            const video_exts = ['mp4', 'avi', 'mkv', 'rmvb', 'rm', 'wmv', 'webm']
            const audio_exts = ['mp3', 'wav', 'ac3', 'wma', 'aac', 'oga']

            const uploadResponse = await this.client.uploadContent(file, {
                rawResponse: false,
                rawResponse: false,
                onlyContentUri: false,
                type: file.type
            });
            const matrixUrl = uploadResponse.content_uri;
            await Client.moi.client.setAvatarUrl(matrixUrl);

        } catch(e) {
        
          } 
    }  
    _getConferenceUserRoom() {
        // Use an existing 1:1 with the conference user; else make one
        var rooms = Client.moi.client.getRooms();
        var confRoom = null;
        for (var i = 0; i < rooms.length; i++) {
            var confUser = rooms[i].getMember(this.confUserId);
            if (confUser && confUser.membership === "join" &&
                    rooms[i].getJoinedMembers().length === 2) {
                confRoom = rooms[i];
                break;
            }
        }
        if (confRoom) {
            return q(confRoom);
        }
        return this.client.createRoom({
            preset: "private_chat",
            invite: [this.confUserId]
        }).then(function(res) {
            return new Room(res.room_id);
        });
    }         
}

const isConferenceUser = function(roomMember) {
    if (roomMember.userId.indexOf("@" + USER_PREFIX) !== 0) {
        return false;
    }
    var base64part = roomMember.userId.split(":")[0].substring(1 + USER_PREFIX.length);
    if (base64part) {
        var decoded = new Buffer(base64part, "base64").toString();
        // ! $STUFF : $STUFF
        return /^!.+:.+/.test(decoded);
    }
    return false;
};

const getConferenceUserIdForRoom = function(roomId) {
    // abuse browserify's core node Buffer support (strip padding ='s)
    var base64RoomId = new Buffer(roomId).toString("base64").replace(/=/g, "");
    return "@" + USER_PREFIX + base64RoomId + ":" + DOMAIN;
};