import React from "react";
import "./styles.css";
import Picture from "../../assets/images/backgraound.png";
import Placeholder from "../../assets/svg/placeholder.svg";
import { SideBarData } from "./SideBarData.js";
import { NavLink, useHistory } from "react-router-dom";
import Button from "../commen/button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";

function SideBar(props) {
    const history = useHistory();
    const {
        title = "Noman Qadir",
        workSpace = "workspace setting",
        image
    } = props;
    let Footer = "© 2023. All Rights Reserved";

    const userName = (val) => {
        if (val?.includes("_")) {
            return val?.split(":")[0].split("@")[1].split("_")[0];
        }

        return val?.split(":")[0].split("@")[1];
    };
    const dispatch =useDispatch()
    const LogOut = () =>{
        dispatch(logout())
        history.push("/");
    
        localStorage.clear()
        
    }

    return (
        <div className="sidebar_wrapper">
            <div className="sidebar_profile_row">
                <div className="sidebar_profile_circle">
                    <div className="profile_circle">
                        <img
                            src={image ? Picture : Placeholder}
                            alt="profile"
                        />
                    </div>
                </div>
                <div className="sidebar_profile_title">
                    <p className="">{userName(localStorage.getItem("mxvr_user_id"))}</p>
                    {/* <NavLink to="/" className="nav_color">
                        {workSpace}
                    </NavLink> */}
                </div>
            </div>
            <div className="sidebar_links_row">
                {SideBarData.map((items, index) => {
                    return (
                        <NavLink

                            key={index}
                            to={items.path ? items.path : "/desktop"}
                            id="dark"
                            className="navlink_"
                        >
                            <div className="navlink_wrapper">
                                <div className="navlink_icon">{items.icon}</div>
                                <div className="navlink_text">
                                    {items.title}
                                </div>
                                <div className="navlink_message">
                                    {items.message_counter ? (
                                        <div className="message_circle_ heading3">
                                            {items.message_counter}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </NavLink>
                    );
                })}
            </div>
            <div className="button_wrapper">
             {/* <Navigate to="/login" replace={true}><Button title="LogOut" onClick={LogOut}/></Navigate> */}
                <Button title="LogOut" onClick={LogOut}/>
            </div>
            <div className="sidebar_fotter_row heading3">{Footer}</div>
        </div>
    );
}

export default SideBar;
