import { Modal } from '@material-ui/core'
import React, { Children } from 'react'

function ModalReuse({children,open,setModalOpen}) {

  return (
    <Modal  
    open={open}
    onClose={() => setModalOpen(false)}
    // aria-labelledby="modal-modal-title"
    // aria-describedby="modal-modal-description"
    >

        {children}
    </Modal>
  )
}

export default ModalReuse