import React, { useState } from "react";
import useGeoLocation from "../../../hooks/useGeoLocation";
import MainWrapper from "./MainWrapper";
import LocationIcon from "../../../assets/svg/LocationIcon.svg";
import CaseCamera from "./CaseCamera";
import CaseDocument from "./CaseDocument";
import CaseAudio from "./CaseAudio";
import CaseGallery from "./CaseGallery";
import { useGlobalContext } from "../../ContextGlobal/ContectGloval";
const ActionWrapper = (props) => {
    const {
        // setOpenWrapper,
        // openWrapper,
        sendLocationCallBack,
        replySendLocationCallback,
        room,
        sendImageCallBack,
        replyUploadFileCallback,
        sendVideoCallBack,
        sendAudioCallBack
    } = props;
    const context = useGlobalContext();
    let currentLocation = useGeoLocation();
    // console.log("706 location_location", currentLocation);
    
    const handleSubmit = () => {
        console.log("currentLocationbefore",currentLocation)

        if(currentLocation?.error?.message==="User denied Geolocation")
        {
        alert("Please Allow the location...")
        context.setOpenWrapper(false);
        context.setReplyMessage("");
        return
        }
        console.log("currentLocationafter",currentLocation)
        let imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${currentLocation.coordinates.lat},${currentLocation.coordinates.lng}&format=png&&zoom=14&size=300x150&scale=1&language=en&client=gme-whatsappinc&markers=color%3Ared%7C31.4692352%2C+74.2716913&style=element:geometry%7C&key=AIzaSyDQUn84UAmAt2ZUaEXaGtIvUACfaxDcn0g`;
        let locationUrl = `https://maps.google.com/maps?q=${currentLocation.coordinates.lat},${currentLocation.coordinates.lng}&z=17&hl=en`;
        const geo_uri = `geo:${currentLocation.coordinates.lat},${currentLocation.coordinates.lng}`;

        let content = {
            geo_uri: `geo:${currentLocation.coordinates.lat},${currentLocation.coordinates.lng}`,
            msgtype: "m.location"
        };
        // alert("715"+imageUrl);
        // alert("716"+locationUrl);
        if (context.replyMessage === "") {
            sendLocationCallBack(geo_uri, room, imageUrl, locationUrl);
            context.setOpenWrapper(false);
        } else {
            replySendLocationCallback(
                context.selectedRoom.roomId,
                context.replyMessage,
                geo_uri,
                imageUrl,
                locationUrl
            );
            context.setOpenWrapper(false);
            context.setReplyMessage("");
        }
    };

    switch (context.openWrapper) {
        case "location":
            return (
                <MainWrapper>
                    <div className="d-flex flex-column align-items-center text-center w-100">
                        <img
                            src={LocationIcon}
                            alt="location"
                            height="50"
                            width="50"
                        />
                        <h4 className=" mt-3 w-50">
                            What location type do you want to share?
                        </h4>
                        <button
                            type="submit"
                            style={{width:"200px"}}
                            className="mt-3 btn btn-primary"
                            onClick={handleSubmit}
                        >
                            My Current Location
                        </button>
                    </div>
                </MainWrapper>
            );
        case "camera":
            return (
                <MainWrapper>
                    <CaseCamera
                        sendVideoCallBack={sendVideoCallBack}
                        sendImageCallBack={sendImageCallBack}
                        replyUploadFileCallback={replyUploadFileCallback}
                        room={room}
                    />
                </MainWrapper>
            );
        case "gallery":
            return (
                <MainWrapper>
                    <CaseGallery
                        sendImageCallBack={sendImageCallBack}
                        replyUploadFileCallback={replyUploadFileCallback}
                        room={room}
                    />
                </MainWrapper>
            );
        case "document":
            return (
                <MainWrapper>
                    <CaseDocument
                        sendImageCallBack={sendImageCallBack}
                        replyUploadFileCallback={replyUploadFileCallback}
                        room={room}
                    />
                </MainWrapper>
            );
        case "audio":
            return (
                <MainWrapper>
                    <CaseAudio
                        replyUploadFileCallback={replyUploadFileCallback}
                        sendAudioCallBack={sendAudioCallBack}
                        room={room}
                    />
                </MainWrapper>
            );
        default:
            return (
                <MainWrapper>
                    <div className="w-100 text-center mt-5">
                        <h4>You have nothing in this component.</h4>
                    </div>
                </MainWrapper>
            );
    }
};

export default ActionWrapper;
