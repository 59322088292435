import React, { useState, useRef, useEffect } from "react";
import SendIcon from "@material-ui/icons/Send";
import { Tooltip } from "@material-ui/core";
import { useGlobalContext } from "../../ContextGlobal/ContectGloval";
import imageCompression from "browser-image-compression";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
import { Button } from "react-bootstrap";
import ImageSvg from "../../../assets/svg/Image.svg";

function CaseGallery({ sendImageCallBack, room, replyUploadFileCallback }) {
    const inputRef = useRef(null);
    const context = useGlobalContext();
    const hiddenFileInput = React.useRef(null);

    const [selectedFile, setSelectedFile] = useState();
    const [thumbnail, setThumbnail] = useState();
    const [imageSrc, setImageSrc] = useState();
    useEffect(() => {
        // console.log("i am called", context.dropImage);
        // alert("called")
        if (context?.openInputAuto === "gallery")
            document.getElementById("abc").click();

        if (context?.dropImage) {
            context?.setLoading(false);

            setThumbnail(context.dropImage);
            setSelectedFile(context.dropImage);
            setImageSrc(URL.createObjectURL(context.dropImage));
        }
    }, [context.dropImage, context.openInputAuto]);

    const handleSubmitImage = (e) => {
        const file = e.target.files[0];
        const image_exts = ["jpg", "gif", "png", "bmp", "tiff", "jpeg", "webp"];
        const video_exts = ["mp4", "avi", "mkv", "rmvb", "rm", "wmv", "webm"];
        let fileExtension = file.name.split(".")[1];
        if (image_exts.indexOf(fileExtension) >= 0) {
            const optionsCompress = {
                maxSizeMB: 1,
                maxWidthOrHeight: 500,
                useWebWorker: true
            };
            imageCompression(file, optionsCompress).then((compressedImg) => {
                setThumbnail(compressedImg);
                setSelectedFile(compressedImg);
                console.log("compressedImg",compressedImg)
                setImageSrc(URL.createObjectURL(compressedImg));
            });
        } else if (video_exts.indexOf(fileExtension) >= 0) {
            let numberOfThumbnails = 0;
            generateVideoThumbnails(file, numberOfThumbnails).then(
                (thumbnailArray) => {
                    fetch(thumbnailArray[0])
                        .then((res) => res.blob())
                        .then((blob) => {
                            const thumb = new File([blob], "img.jpeg");
                            setThumbnail(thumb);
                            setSelectedFile(file);
                            setImageSrc(URL.createObjectURL(file));
                        });
                }
            );
        }
    };

    const handleImageSend = () => {
        if (context.replyMessage === "") {
            sendImageCallBack(room, selectedFile, thumbnail);
        } else {
            replyUploadFileCallback(
                room.roomId,
                context.replyMessage,
                selectedFile,
                thumbnail
            );
        }
        context.setReplyMessage("");
        setSelectedFile();
        context.setOpenWrapper(false);
        setImageSrc("");
    };
    return (
        <div className="d-flex flex-column align-items-center text-center w-100">
            <h4 className="w-50">Images</h4>
            <div style={{ position: "relative" }}>
                <img src={ImageSvg} />

                {context?.uploadButtonShow ? (
                    ""
                ) : (
                    <>
                        {/* <h6 className="w-50 mt-1">Select image file</h6> */}
                        {!selectedFile && (
                            <>
                                {/* <div   
 >
                <Button
              
                    onClick={() => {
                        hiddenFileInput.current.click();

                    }}
                    className="mt-1"
                    htmlFor="filePicker"
                >
                    Upload a file
                </Button>
            </div>
             <div>
             <input
             id="abc"
                 type="file"
                 name="myImage"
                 ref={hiddenFileInput}
                 accept="video/*,image/*"
                 onChange={handleSubmitImage}
                 style={{ display: "none" }}
             />
         </div> */}
                            </>
                        )}
                    </>
                )}

                {context?.loading && (
                    <p className="mt-2">Image Uploading...Wait</p>
                )}
                <div className="mt-3">
                    {selectedFile && (
                        <div>
                            <div
                                style={{
                                    width: "auto",
                                    display: "flex",
                                    height: "auto"
                                }}
                            >
                                <img
                                    style={{
                                        width: "555px",
                                        height: "370px",
                                        objectFit: "contain",
                                        background: "white",boxShadow:"0 3px 12px #ccc"
                                    }}
                                    src={imageSrc}
                                    alt="dljkck"
                                />
                                
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div style={{ position: "absolute", bottom: "5px",display:"flex" }}>
                {selectedFile && (
                    <div style={{position:"relative"}}>
                    <span>
                        {/* <label
                            className="text-danger "
                            onClick={() => {
                                setSelectedFile();
                                setImageSrc();
                                inputRef.current.value = null;
                            }}
                        >
                            X
                        </label> */}
                        <img
                            className="buttonImage imageGallery"
                            src={imageSrc}
                            alt="dljkck"
                        />
                    </span>
                    <div
                    style={{
                        position: "absolute",
                       
                        width: "25px",
                        height: "25px",
                        padding: "0px 6px",
                        top: "1px",
                        right:"2px"
                    }}
                >
                    <label style={{color:"#fff",fontSize:"14px",fontWeight:"bolder",cursor:"pointer"}}
                        className="t-flex"
                        onClick={() => {
                            setSelectedFile();
                            setImageSrc();
                            inputRef.current.value = null;
                        }}
                    >
                        X
                    </label>
                </div>
                </div>

                )}

{!selectedFile?
                <button
                    onClick={() => {
                        hiddenFileInput.current.click();
                    }}
                    className="buttonImage"
                >
                    +
                </button>
                :""
}
                <div>
                    <input
                        id="abc"
                        type="file"
                        name="myImage"
                        ref={hiddenFileInput}
                        accept="video/*,image/*"
                        onChange={handleSubmitImage}
                        style={{ display: "none" }}
                    />
                </div>
            </div>
            <Tooltip title="Sent">
                <div className="sendButton">
                    <button
                        onClick={handleImageSend}
                        className="btnSend-primary "
                        disabled={selectedFile ? false : true}
                    >
                        <SendIcon />
                    </button>{" "}
                </div>
            </Tooltip>
        </div>
    );
}

export default CaseGallery;
