import React from "react";
import { useGlobalContext } from "../../ContextGlobal/ContectGloval";
const MainWrapper = (props) => {
    const { children } = props;
    const context = useGlobalContext();
    return (
        <div  className="dropUpCustomDiv">
            <div className="d-flex flex-column align-items-end">
                <button
                    type="button"
                    className="btn-close float-end p-3"
                    aria-label="Close"
                    onClick={() => context.setOpenWrapper("")}
                ></button>
                {children}
            </div>
        </div>
    );
};

export default MainWrapper;
