import React, { useState, useEffect, useRef } from "react";
import Search from "../commen/search";
import { BsArrowRight } from "react-icons/bs";
import "./styles.css";

const SearchMessages = (props) => {
      const { searchWindow, setSearchWindow, room, messages,search,getNextPage,
            getPreviousPage } = props;
      const [msgQuery, setMsgQuery] = useState("");
      // const [selectedMsg, setSelectedMsg] = useState(null);
   //   console.log("Search Messages Props:", { room, messages });
   //   console.log("Room Members:", room?.members ? Object.values(room.members) : null);

      let RoomMembers = room && room.members ? Object.values(room.members) : null;
      let inputRef = useRef(null)
useEffect(() => {
      let term=msgQuery;
   //   console.log("messageQuery",msgQuery)

search(term)
}, [msgQuery])

      useEffect(() => {
            if (searchWindow) {

                  inputRef.current = setTimeout(() => {
                        const input = document.getElementById("searchMsgInput")
                        input.focus();


                  }, 500)


            }

            return (() => {
                  clearTimeout(inputRef.current)
            })

      }, [searchWindow])

      const handleCloseSearchWindow = () => {
            setSearchWindow(false);
            setMsgQuery("");
      };

      const selectedMsgHandler = (message) => {
         //   console.log("this msg is selected", message);
            setSearchWindow(false);
            setMsgQuery("");
            let element = window.document.getElementById(message.event_id);
            // let child = element.querySelector('.text-message')
            element.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  // inline: "nearest"
            });
      
            element.classList.add("scrollTo");
            setTimeout(() => {
                  element.classList.remove("scrollTo");
            }, 1500);
         
      };

      return (
            <div className={`search_msgs_main ${searchWindow ? "smm_open" : ""}`}>
                  <div
                        onClick={() => {
                              handleCloseSearchWindow()
                              clearTimeout(inputRef.current)
                        }}
                        className="smm_closeWindow"
                  >
                        <BsArrowRight />
                  </div>

                  <div className="smm_searchBar">
                        <Search
                              searchMsg={true}
                              msgQuery={msgQuery}
                              setMsgQuery={setMsgQuery}
                              className="noBorder"
                              placeholder="Search Messages Here..."
                              searchWindow={searchWindow}
                        />
                  </div>

                  <div className="smm_msgs_container">
                        {messages
                              ?.filter((message) => {
                                    if (msgQuery === "") {
                                          return;
                                    } else if (
                                          message?.content?.body
                                                ?.toLowerCase()
                                                .includes(msgQuery?.toLowerCase())
                                    ) {
                                          return message;
                                    }
                              })
                              .map((message) => (
                                    <div
                                          onClick={() => 
                                                
                                                {selectedMsgHandler(message)
                                                // getNextPage()
                                                }
                                          }
                                          className="smm_msg_main"
                                    >
                                          <div className="smm_mm_row1">
                                                <div className="mm_r1_from">
                                                      {
                                                            RoomMembers.find(
                                                                  (member) =>
                                                                        member.userId === message.sender
                                                            ).name
                                                      }
                                                </div>

                                                <div className="mm_r1_time">
                                                      {new Intl.DateTimeFormat("en-US", {
                                                            hour: "2-digit",
                                                            minute: "2-digit"
                                                      }).format(message.origin_server_ts)}
                                                </div>
                                          </div>

                                          <div className="smm_mm_row2">
                                                <div className="mm_r2_msg">
                                                      {message.content.body}
                                                </div>
                                          </div>
                                    </div>
                              ))}
                  </div>
            </div>
      );
};

export default SearchMessages;
