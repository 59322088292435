import React, { useState } from 'react';
import { searchUserCreateRoom, userSearch } from '../../../actions/desktop';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";

const InviteUser = (props) => {
      const { setOpen, inviteUserListCallBack, roomInfo } = props
      const [invitedUser, setInvitedUser] = useState([])
      const [invitedUserFun, setInvitedUserFun] = useState([])
      const [search, setSearch] = useState("")
      const dispatch = useDispatch()

      const handleClose = () => {
            setOpen(false)
      }

      const handleSearch = (e) => {
            setSearch(e.target.value)
            // if (e.key === 'Enter') {
                  const valueFromUser = e.target.value
                  dispatch(searchUserCreateRoom(valueFromUser))
                  dispatch(userSearch(valueFromUser))
            // }
      }

      const users = useSelector((state) => {
            return state.desktop.searchUser;
      });
      //for testing case only ahmad
      const testingCase = useSelector((state) => {
            return state.desktop.testingCase;
      });
     // console.log("testingCase",testingCase)

      const handleCheckUser = (user, e) => {
           // console.log("usersss",user)
            if(user.matrix_user_id == null && user.matrix_user_id == "")
            return ;
            if (e.target.checked) {
                if (user.matrix_user_id != null && user.matrix_user_id !== "") {
                    setInvitedUserFun((invitedUser) => [...invitedUser, user]);
    
                }
            } else {
                setInvitedUserFun(
                    invitedUserFun.filter(
                        (item) => item?.user_name !== e.target.value
                    )
                );
    
               // console.log("calledui", invitedUserFun?.user_name);
            }
        };
        const removeUser = (user) => {
            setInvitedUserFun(
                invitedUserFun.filter((item) => item?.user_name !== user?.user_name)
            );
    
        };
      const InviteUsers = () => {
            if (invitedUserFun.length !== 0) {
                 // console.log("invitedUser", invitedUserFun, roomInfo.roomId)
                  let roomId = roomInfo.roomId
                  // inviteUserCallBack(roomId, invitedUser)
                  inviteUserListCallBack(roomId, invitedUserFun)
                  handleClose()
            } else {
                  alert("User not selected!")
            }
      }
      const checkedFun = (user) => {
            return Boolean(
                invitedUserFun.find((item) => {
                    return item?.user_name == user.user_name;
                })
            );
        };

      return (
            <div id="myModal" className="modalCustom">
                  <div className="modalCustom-content">
                        <div className="modal-header">
                              <div style={{fontSize:"24px",fontFamily:"Manrope"}} className="modal-title h4">Invite User </div>
                              <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
                        </div>
                        <div className="form-outline">
                              <input type="search" placeholder='Search user' className="form-control" onKeyDown={handleSearch} />
                        </div>
                        <div>
                        <div style={{ display: "flex" }}>
                            {invitedUserFun.map((user, i) => (
                                <div style={{ display: "flex" }} key={i}>
                                    <div>
                                        {user.user_name}
                                        {/* <div className="">
                                        {user.user_email}
                                    </div> */}
                                    </div>
                                    <div>
                                        <i
                                            className="fa ml-2 fa-close "
                                            onClick={() => removeUser(user)}
                                        ></i>
                                    </div>
                                </div>
                            ))}
                        </div>
                      
                    </div>
                        <div className="list-group vh-30 d-block" style={{ zIndex: 1 }}>
                    {search &&
                        (users?.length > 0
                            ? users.map((user, i) => (
                                user.matrix_user_id===null?"":
                                  <div key={i}>
                                      <label className="list-group-item listItemBackground">
                                          <input
                                              className="form-check-input me-1"
                                              type="checkbox"
                                              value={user?.user_name}
                                              checked={checkedFun(user)}
                                              onChange={(e) =>
                                                  handleCheckUser(user, e)
                                              }
                                          />
                                          {user.user_name}
                                          <div className="ms-2 me-auto fw-normal">
                                              {user.user_email}
                                          </div>
                                      </label>
                                  </div>
                                            
                              ))
                            : "")}
                </div>                        <div className="list-group overflow-auto vh-30 mt-2">
                              {
                                    invitedUser.map((user, i) => (
                                          <div key={i}>
                                                <label className="list-group-item listItemBackground">
                                                      {user.user_name}
                                                      <div className="ms-2 me-auto fw-normal">
                                                            {user.user_email}
                                                      </div>
                                                </label>

                                          </div>
                                    ))
                              }
                        </div>
                        <div className='d-flex justify-content-end'>
                        <Button
                    style={{ width: "150px" }}
                    variant="primary"
                    onClick={InviteUsers}
                >
                   Invite
                </Button>
                              {/* <button className="btn btn-primary m-1" onClick={}></button> */}
                              {/* <button className="btn btn-secondary m-1" onClick={handleClose}>Close</button> */}
                        </div>
                  </div>
            </div>
      )
}

export default InviteUser