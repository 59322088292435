import React, { Component } from "react";
import { connect } from "react-redux";
import { register, authenticate } from "../../actions/auth";
import { withRouter } from 'react-router';
import Logo from "../../assets/images/kuumba_logo.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom";
class Register extends Component {
  constructor(props) {
    super(props);
    this.reg = this.reg.bind(this);
  }
  componentDidMount() {

  }

  reg(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("password") === data.get("confirmPassword")) {
      let payload = {
        user_name: data.get("firstName") + data.get("lastName"),
        user_email: data.get("email"),
        user_password: data.get("password"),
      };
      this.props
        .register(payload)
        .then((data) => {
          // console.log("data_data_reg", data)
          this.props.history.push('/login');
        })
        .catch((e) => {
          alert('Please try again!');
          // console.log(e);
        });
    } else {
      alert("Password not matched!")
    }
  }

  render() {
    
    return (

      <div className="container-fluid bg-chat-section chat-wrappr">
        <div className="row">
          <div className="col-12">
            <select className="vodiapicker ">
              <option value="en" className="test" data-thumbnail="images/united-states.png">English</option>
              <option value="gr" data-thumbnail="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/4H_Emblem.svg/1000px-4H_Emblem.svg.png">Italian</option>
            </select>
            <div className="lang-select float-end">
              <button className="btn-select" value=""></button>
              <div className="b">
                <ul id="a"></ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div  className="col-md-3 d-flex align-items-center justify-content-center">
            {/* <img src={Logo} alt="kuumba_logo" className="regFormImg" /> */}
          </div>
          <div className="col-md-6">
            <div style={{borderRadius:"12px",marginRight:"30px"}} className="form-body bg-white">
              <div className="row">
                <div className="form-holder">
                  <div className="form-content">
                    <div className="form-items">
                      <h3 className="text_login">Account Registration</h3>
                      <div className="alrts ">
                        <img src={require("../../assets/images/alert.png")} alt="" />
                       
                      </div>
                      <p className="pb-3">
                          Please follow all the steps of the registration process and provide accurate information.
                          {/* Upon form submission you will be sent a text to start verification process. */}
                        </p>
                      <form className="requires-validation" onSubmit={this.reg}>
                        <div className="row">
                          <div className="col-md-6">
                            <label for="name-f text_login">Name</label>
                            <label style={{color:"red"}} for="name-f text_login">*</label>

                            <input className="form-control" type="text" name="firstName" placeholder="First" required />
                          </div>
                          {/* <div className="col-md-4">
                            <label for="name-f"></label>
                            <input className="form-control" type="text" name="middleName" placeholder="Middle (optional)" />
                          </div> */}
                          <div className="col-md-6">
                            <label for="name-f"></label>
                            <input className="form-control" type="text" name="lastName" placeholder="Last" required />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <label for="name-f" className="pt-3 text_login">Email Address</label>
                            <label style={{color:"red"}} for="name-f text_login">*</label>

                            <input className="form-control" type="email" name="email" placeholder="Enter Your Email Address" required />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label  for="name-f" className="pt-3 text_login">Password</label>
                            <label style={{color:"red"}} for="name-f text_login">*</label>

                            <input className="form-control" type="password" name="password" placeholder="Password" required />
                          </div>
                          <div className="col-md-6">
                            <label for="name-f" className="pt-3"></label>
                            <input className="form-control mt-4" type="password" name="confirmPassword" placeholder="Password Again" required />
                          </div>
                        </div>

                        <div className="form-check mt-3">
                          <input className="form-check-input" type="checkbox" value="" id="invalidCheck" required />
                          <label className="form-check-label text_login">I agree to the term of Use and Privacy Policy</label>
                        </div>
                        
                        <div className="form-button mt-3">
                          <button style={{width:"100%"}} id="submit" type="submit" className="btn btn-primary py-3 mt-3 ">REGISTER</button>
                        </div>
                        <p onClick={()=>this.props.history.push('/login')}>
                        <span className="text_login ">Already have an account? <a  className="text-underline  text_login"><strong>Click here to login</strong></a></span>
                        </p>
                        <p className=" mb-0 position-relative ">
                          <ul className="list-unstyled text_login   d-flex">
                            <li className="me-3 text_login">&copy; 2023 - *</li>
                            {/* <li className="me-3 text_login"><a className=" text-decoration-none text_login" href="">Privacy Policy</a></li>
                            <li className="me-3"><a className=" text-decoration-none text_login" href="">Terms of Use</a></li> */}
                          </ul>
                        </p>
                       
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div  className="col-md-3 d-flex align-items-center justify-content-center">
            {/* <img src={Logo} alt="kuumba_logo" className="regFormImg" /> */}
          </div>
        </div>

      </div>
    )
  }
}
export default connect(null, { register, authenticate })(withRouter(Register));