import React from "react";
import "./styles.css";
import { Modal, Button } from "react-bootstrap";

const Popup = (props) => {
    // console.log("popup", props);
    const { vedioToggle, setVideoToggle, urlThumbnail,urlView } = props;
    const handleClose = () => {
        setVideoToggle(false);
    };
    return (
        <>
            <Modal size="lg" show={vedioToggle} onHide={handleClose} centered>
                {/* <Modal.Header closeButton style={{backgroundColor: "black"}}>
                    <Modal.Title>Create Group Chat</Modal.Title>
                </Modal.Header> */}

                <Modal.Body style={{backgroundColor: "black"}}>
                <video autoPlay controls>
  <source src={urlView} type="video/mp4"/>
  Your browser does not support the video tag.
</video>
                </Modal.Body>
            </Modal>
        </>
        // <div className="popup_container">
        //     <div className="popup_vedio">

        //     </div>
        // </div>
    );
};

export default Popup;
