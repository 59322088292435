import {
  STAGES,
  } from "../actions/types";
  const initialState = [];
  function stagesReducer(currentState = initialState, action) {
    const { type, payload } = action;

    switch (type) {
      case STAGES:
        return {
          stages: payload
        };

        default:
        return currentState;
    }
  };
  export default stagesReducer;