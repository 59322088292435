import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import { withRouter } from 'react-router';

import { Redirect } from "react-router-dom";

class Logout extends Component {
  constructor(props) {
    super(props);

  }
  render(){
    // localStorage.removeItem('currentUser');
    // localStorage.removeItem('token');  
    // this.props.logout();
    this.props.logout();
    return(<Redirect to="/" />);

  }
}
  export default connect(null, { logout })(Logout);