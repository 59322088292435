import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { searchUserCreateRoom } from "../../../actions/desktop";
import { useDispatch, useSelector } from "react-redux";

const NewChat = (props) => {
    const { newChatOpen, setNewChatOpen } = props;
    const [search, setSearch] = useState("");
    const [invitedUser, setInvitedUser] = useState([]);
    const [invitedUserFun, setInvitedUserFun] = useState([]);

    const dispatch = useDispatch();

    const handleClose = () => {
        setNewChatOpen(false);
    };

    const handleSearch = (e) => {
        setSearch(e.target.value);
        if (e.key === "Enter") {
            const valueFromUser = e.target.value;
            dispatch(searchUserCreateRoom(valueFromUser));
        }
    };

    const users = useSelector((state) => {
        return state.desktop.searchUser;
    });
    const handleSelectUser = (user) => {
       // console.log("userLIst",user)
        if (user.matrix_user_id != null && user.matrix_user_id !== "") {
            if (invitedUser.includes(user.matrix_user_id)) {
                let filteredArray = invitedUser.filter(function (e) {
                    return e !== user.matrix_user_id;
                });
                setInvitedUser(filteredArray);
                let filteredArrayFun = invitedUserFun.filter(function (e) {
                    return e.matrix_user_id !== user.matrix_user_id;
                });
                setInvitedUserFun(filteredArrayFun);
            } else {
                if (invitedUser.length < 1) {
                    setInvitedUser((invitedUser) => [
                        ...invitedUser,
                        user.matrix_user_id
                    ]);
                    setInvitedUserFun((invitedUser) => [...invitedUser, user]);
                } else {
                    alert("Another user already selected!");
                }
            }
        }
    };
    const handleCreateChat = () => {
       // console.log("InviteUser",invitedUser)
        if (invitedUser.length !== 0) {
           // console.log("matrix_user_id");
        } else {
            alert("User not selected!");
        }
    };

    return (
        <Modal  size="lg" show={newChatOpen} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Direct Messages</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="form-outline">
                    <input
                        type="search"
                        className="form-control"
                        onKeyDown={handleSearch}
                    />
                </div>
                <div
                    className="list-group overflow-auto vh-30 d-block position-fixed"
                    style={{ zIndex: 1 }}
                >
                    {search &&
                        (users?.length > 0
                            ? users.map((user, i) => (
                                  <div
                                      key={i}
                                      onClick={() => handleSelectUser(user)}
                                  >
                                      <label className="list-group-item listItemBackground">
                                          {/* <input
                                              className="form-check-input me-1"
                                              type="checkbox"
                                              onChange={() =>
                                                  handleSelectUser(user)
                                              }
                                              checked={
                                                  invitedUser[0] ===
                                                  user.matrix_user_id
                                              }
                                          /> */}
                                          {user.user_name}
                                          <div className="ms-2 me-auto fw-normal">
                                              {user.user_email}
                                          </div>
                                      </label>
                                  </div>
                              ))
                            : "")}
                </div>
                <div className="list-group overflow-auto vh-30 mt-2">
                    {invitedUserFun.map((user, i) => (
                        <div key={i}>
                            <label className="list-group-item listItemBackground d-flex justify-content-between">
                                <div>
                                    {user.user_name}
                                    <div className="ms-2 me-auto fw-normal">
                                        {user.user_email}
                                    </div>
                                </div>
                                <div>
                                    <i
                                        className="fa fa-close ms-3 pe-auto"
                                        onClick={() => {
                                            setInvitedUserFun([]);
                                            setInvitedUser([]);
                                        }}
                                    ></i>
                                </div>
                            </label>
                        </div>
                    ))}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleCreateChat}>
                    Create Chat
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NewChat;
