import React, { useState, useRef, useEffect } from "react";
import SendIcon from "@material-ui/icons/Send";
import { Tooltip } from "@material-ui/core";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { Button } from "antd";

import Icon, { AudioTwoTone } from "@ant-design/icons";
import "react-h5-audio-player/lib/styles.css";

export default function AudioRecord({
    room,
    sendAudioCallBack,
    menuOpenAudio,
    setAudioUI,
    forStart
}) {
    const stopsvg = () => (
        // <svg
        //     width="20"
        //     height="20"
        //     viewBox="0 0 20 20"
        //     fill="none"
        //     xmlns="http://www.w3.org/2000/svg"
        // >
        //     <path
        //         d="M15 0H5C3.67392 0 2.40215 0.526784 1.46447 1.46447C0.526784 2.40215 0 3.67392 0 5V15C0 16.3261 0.526784 17.5979 1.46447 18.5355C2.40215 19.4732 3.67392 20 5 20H15C16.3261 20 17.5979 19.4732 18.5355 18.5355C19.4732 17.5979 20 16.3261 20 15V5C20 3.67392 19.4732 2.40215 18.5355 1.46447C17.5979 0.526784 16.3261 0 15 0ZM18 15C18 15.7956 17.6839 16.5587 17.1213 17.1213C16.5587 17.6839 15.7956 18 15 18H5C4.20435 18 3.44129 17.6839 2.87868 17.1213C2.31607 16.5587 2 15.7956 2 15V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H15C15.7956 2 16.5587 2.31607 17.1213 2.87868C17.6839 3.44129 18 4.20435 18 5V15Z"
        //         fill="white"
        //     />
        // </svg>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="38"
            height="38"
            x="0"
            y="0"
            viewBox="0 0 24 24"
            xmlSpace="preserve"
        >
            <g>
                <g id="refresh">
                    <path
                        d="m12 2a10 10 0 1 0 10 10 10 10 0 0 0 -10-10zm-1 13a1 1 0 0 1 -2 0v-6a1 1 0 0 1 2 0zm4 0a1 1 0 0 1 -2 0v-6a1 1 0 0 1 2 0z"
                        fill="#000000"
                        data-original="#000000"
                    ></path>
                </g>
            </g>
        </svg>
    );

    const StopIcon = (props) => <Icon component={stopsvg} {...props} />;
    const recordsvg = () => (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="6" r="6" fill="#FF0606" />
        </svg>
    );
    const RecordIcon = (props) => <Icon component={recordsvg} {...props} />;

    const formatTime = (timer) => {
        //console.log("timer", timer);
        const getSeconds = `0${timer % 60}`.slice(-2);
        const minutes = `${Math.floor(timer / 60)}`;
        const getMinutes = `0${minutes % 60}`.slice(-2);

        return `${getMinutes}:${getSeconds}`;
    };
    const [timer, setTimer] = useState(0);
    const [isStop, setIsStop] = useState(false);
    const countRef = useRef(null);
    const [recordstate, setrecordstate] = useState("NONE");
    const [blobURL, setblobURL] = useState("");
    const [condition, setcondition] = useState("true");
    const [download, setdownload] = useState([]);
    useEffect(() => {
        if (forStart) {
            start();
        }
    }, [forStart]);

    const start = () => {
        navigator.mediaDevices.getUserMedia({audio: true})
        .then(stream => {
            setIsStop(false);
        countRef.current = setInterval(() => {
            setTimer((timer) => timer + 1);
        }, 1000);

        //console.log(recordstate);
        //console.log("start");
        setrecordstate(RecordState.START);
        setcondition("false");
        setblobURL("");
          console.log("MediaStreamTrack muted:", stream.getAudioTracks()[0].muted);
        })
        .catch(
            err =>
            {
                console.log(err)
                alert("Please Allow the microphone...");
            }
        )
        
        
    };

    const stop = () => {
        clearInterval(countRef.current);
        //console.log("stop");
        setrecordstate(RecordState.STOP);
        setcondition("true");
        setIsStop(true);
        setTimer(0);
    };

    const onStop = (audioData) => {
        //console.log("audioData", audioData);
        setdownload({ ...download, audioData });
        setblobURL(audioData.url);
    };
    //console.log("bloburl", blobURL);
    const handleAudioSend = () => {
        //console.log("blogurlsdk", blobURL);
        try {
            fetch(blobURL)
                .then((res) => res.blob())
                .then((blob) => {
                    //console.log("blob", blob);
                    const file = new File([blob], "filename.wav");
                    //console.log("file", file);
                    sendAudioCallBack(room, file);
                    setIsStop(false);
                    setblobURL("");
                    setAudioUI(false);
                });
        } catch (error) {
            alert(error);
        }
    };

    return (
        <div>
            {menuOpenAudio && (
                // <ul className="dropUpCustomAudio">
                <div style={{ display: "flex" }}>
                    <AudioReactRecorder
                        canvasWidth="0"
                        canvasHeight="0"
                        backgroundColor={false}
                        foregroundColor={false}
                        state={recordstate}
                        autoPlay
                        onStop={onStop}
                    />
                    {condition === "true" ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flex: "1",
                                width: "100%"
                            }}
                        >
                            <Button
                                style={{ margin: "auto", marginRight: "5px",paddingTop:"0px" }}
                                onClick={start}
                                type="primary"
                                shape="circle"
                                icon={<AudioTwoTone twoToneColor="white" />}
                                size={"large"}
                            />

                            {isStop === true ? (
                                <>
                                    <audio
                                        style={{ margin: "auto", height: 30 }}
                                        controls
                                    >
                                        <source
                                            src={blobURL}
                                            type="audio/mpeg"
                                        />
                                        Your browser does not support the audio
                                        element.
                                    </audio>

                                    <i
                                        className="fa fa-trash"
                                        style={{
                                            margin: "auto",
                                            fontSize: "22px",
                                            pointer: "cursor",
                                            color: "darkgrey"
                                        }}
                                        onClick={() => {
                                            setIsStop(false);
                                            setblobURL("");
                                            setAudioUI(false);
                                        }}
                                    ></i>
                                </>
                            ) : null}
                            {/* <h1 style={{ color: "grey", margin: "auto" }}>|</h1> */}

                            <p
                                style={{
                                    fontSize: "14px",
                                    color: "grey",
                                    display:"flex",
                                    alignItems:"center",
                                    margin: "auto"
                                }}
                            >
                                record audio
                            </p>
                            <Tooltip title="Sent">
                                <div style={{ margin: "auto" }}>
                                    <button
                                        onClick={handleAudioSend}
                                        className="btn btn-primary"
                                        // disabled={ blobURL ? false : true}
                                    >
                                        <SendIcon />
                                    </button>{" "}
                                </div>
                            </Tooltip>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                flex: "1",
                                width: "100%",
                                alignItems: "center"
                            }}
                        >
                            <Button
                                onClick={stop}
                                type="primary"
                                shape="circle"
                                icon={<StopIcon twoToneColor="white" />}
                                size={"large"}
                                style={{
                                    backgroundColor: "tomato",
                                    borderColor: "black",
                                    paddingTop: 0
                                }}
                            />


                            <div
                                style={{
                                    display: "flex",
                                }}
                            >
                                <RecordIcon
                                    style={{
                                        width: "16.5px",
                                        height: "16px",
                                        border: "2px solid #f50202",
                                        borderRadius: "50%",
                                        marginTop: "10px"
                                    }}
                                />
                                <div style={{ marginTop: "6px",marginLeft:"4px" }}>
                                    <p
                                        style={{
                                            fontWeight: "bold",
                                            // paddingLeft: "5px",
                                            color: "red"
                                        }}
                                    >
                                        {formatTime(timer)}
                                    </p>
                                </div>
                            </div>
                            <p
                                style={{
                                    fontSize: "14px",
                                    color: "grey",marginRight:"7px !important"
                                }}
                            >
                                Listening...
                            </p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
