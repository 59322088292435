import React, { useContext, useState, createContext } from "react";
import placeholder from "../../assets/images/kuumba_logo.png"
const GlobalContext = createContext();

export const ContextGlobal = ({ children }) => {
    const [callStart, setStartCall] = useState();
    const [newVideo, setNewVideo] = useState(false);
    const [allMessages, setAllMessages] = useState();
    const [newClose, setNewClose] = useState(false);
    const [roomId, setRoomId] = useState();
    const [open, setOpen] = useState(false);
    const [callName, setCallName] = useState();
    const [loading, setLoading] = useState(false);
    const [customModalOpen,setCustomModalOpen]=useState(false)
    const [row,setRow]=useState()
    const [bottom,setBottom]=useState(false)
    const [openInputAuto,setOpenInputAuto]=useState(false)

    const [dropImage, setDropImage] = useState();
    const [participant, setParticipant] = useState();
    const [count, setCount] = useState();
    const [lastMessage,setLastMessage]=useState()
    const [audioOnly, setAudioOnly] = useState(false);
    const [lastVideoCallEventID, setLastVideoCallEventID] = useState("");
    const [lastAudioCallEventID, setLastAudioCallEventID] = useState("");
    const [videoCallStart, setVideoCallStart] = useState(false);
    const [createAudio, setCreateAudio] = useState(false);
    const [createVideo, setCreateVideo] = useState(false);
    const [incomingCall, setIncomingCall] = useState("");
    const [uploadButtonShow, setUploadButtonShow] = useState(false);
    const [openIncomingCall, setOpenIncomingCall] = useState(false);
    const [replyMessage, setReplyMessage] = useState("");
    const [forwardMessage, setForwardMessage] = useState("");
    const [getRoom, setGetRoom] = useState();
    const [scrollBottom, setScrollBottom] = useState(false);
    const [acceptCallerName,setAcceptCallerName]=useState("")

    const [scroll, setScroll] = useState();

    // const [currentUser, setCurrentUser] = useState();
    //const [messageCallBackForward, setMessageCallBackForward] = useState();

    const [openWrapper, setOpenWrapper] = useState();
    const [modalOpen, setModalOpen] = useState(false);

    const [selectedRoom, setSelectedRoom] = useState();
    const [viewMedia, setViewMedia] = useState("");
    const [viewMediaVideo, setViewMediaVideo] = useState(false);
    const [hideRejectAccept,setHideRejectAccept]=useState(true)
    const [groupName,setGroupName]=useState()
    const [goBottom, setGoBottom] = useState(true);
    function notifyMe() {
        if (!window.Notification) {
            console.log('Browser does not support notifications.');
        } else {
            // check if permission is already granted
            if (Notification.permission === 'granted') {
                // show notification here
                var notify = new Notification('New Messages', {
                    body: 'Kuumba Application',
                    icon: 'https://kuumbav2.aspiretechnologies.dk/static/media/kuumba_logo.ca2e27aecf277b5f4650.png',
                });
            } else {
                // request permission from user
                Notification.requestPermission().then(function (p) {
                    if (p === 'granted') {
                        // show notification here
                        var notify = new Notification('Hi theres!', {
                            body: 'How are you doing?',
                            icon: 'https://bit.ly/2DYqRrh',
                        });
                    } else {
                        console.log('User blocked notifications.');
                    }
                }).catch(function (err) {
                    console.error(err);
                });
            }
        }
    }
    

    // console.log("allmessagesincontact",customModalOpen);
    return (
        <GlobalContext.Provider
            value={{
                newVideo,
                setNewVideo,
                goBottom, setGoBottom,
                groupName,setGroupName,
                hideRejectAccept,setHideRejectAccept,
                lastMessage,setLastMessage,
                openInputAuto,setOpenInputAuto,
                getRoom,
                row,setRow,
                notifyMe,
                setGetRoom,
                forwardMessage,
                setForwardMessage,
                open,
                setOpen,
                customModalOpen,setCustomModalOpen,
                modalOpen,
                bottom,setBottom,
                acceptCallerName,setAcceptCallerName,
                setModalOpen,
                /// messageCallBackForward, setMessageCallBackForward,
                audioOnly,
                setAudioOnly,

                allMessages,
                setAllMessages,

                uploadButtonShow,
                setUploadButtonShow,

                count,
                setCount,

                callName,
                setCallName,

                participant,
                setParticipant,
                // currentUser, setCurrentUser,
                roomId,
                setRoomId,
                scrollBottom,
                setScrollBottom,
                newClose,
                setNewClose,

                loading,
                setLoading,

                callStart,
                setStartCall,
                scroll,
                setScroll,

                createAudio,
                setCreateAudio,

                //Video Call start by my self Check
                videoCallStart,
                setVideoCallStart,
                dropImage,
                setDropImage,

                // Video Call Event ID
                lastVideoCallEventID,
                setLastVideoCallEventID,

                createVideo,
                setCreateVideo,

                lastAudioCallEventID,
                setLastAudioCallEventID,

                incomingCall,
                setIncomingCall,

                openIncomingCall,
                setOpenIncomingCall,

                replyMessage,
                setReplyMessage,

                openWrapper,
                setOpenWrapper,
                // Selected Room Via Left Pane
                selectedRoom,
                setSelectedRoom,
                //Selected Media
                viewMedia,
                setViewMedia,
                viewMediaVideo,
                setViewMediaVideo
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export const useGlobalContext = () => useContext(GlobalContext);
