import React from "react";
import {
    BsFillChatTextFill,
    BsFileEarmarkText,
    BsFillPersonFill
} from "react-icons/bs";

export const SideBarData = [
    {
        title: "Message",
        path: "",
        icon: <BsFillChatTextFill className="icon_" />,
        // message_counter: "5"
    },
    // {
    //     title: "Profile",
    //     path: "",
    //     icon: <BsFillPersonFill className="icon_" />
    // },
    // {
    //     title: "Documents",
    //     path: "",
    //     icon: <BsFileEarmarkText className="icon_" />
    // }
];
