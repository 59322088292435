//TODO remove these once done start
export const CREATE_TUTORIAL = "CREATE_TUTORIAL";
export const RETRIEVE_TUTORIALS = "RETRIEVE_TUTORIALS";
export const UPDATE_TUTORIAL = "UPDATE_TUTORIAL";
export const DELETE_TUTORIAL = "DELETE_TUTORIAL";
export const DELETE_ALL_TUTORIALS = "DELETE_ALL_TUTORIALS";
//End TODO remove these once done start

//auth constants
export const AUTHENTICATE = "AUTHENTICATE";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";

//DESKTOP
export const FETCH_DESKTOP = "FETCH_DESKTOP";
export const FETCH_DESKTOP_ERROR = "FETCH_DESKTOP_ERROR";
export const SELECT_ROOM = "SELECT_ROOM";

// SEARCH USERS
export const SEARCH_USER_CREATE_ROOM = "SEARCH_USER_CREATE_ROOM"
export const SEARCH_USER_CREATE_ROOM_ERROR = "SEARCH_USER_CREATE_ROOM_ERROR"
export const TESTING_CASE="TESTING_CASE"
//MESSAGING_SDK
export const SYNC_ROOMS = "SYNC_ROOMS";
export const SET_CLIENT = "SET_CLIENT";



//RefTables
export const STAGES = "STAGES";
export const STATUSES = "STATUSES";


