import React, {  Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from 'react-router';
import {  Link } from "react-router-dom";
import logo1 from '../../assets/images/logo-1.png';

class NavBar extends Component {
    constructor(props) {
      super(props);
    }
  
    componentDidMount(){

    }

    render(){
        const {isLoggedIn} = this.props;

        if (isLoggedIn){
            return(
            <div>
        <aside id="sidebar" className="sidebar text-center" >
          <div className="menu">

           <div className="row mb-4">
               <div className="col-12">
                <div className="logo-holder mb-2">
                    <img src={logo1} className="img-fluid d-block " width="286" alt="Mental Logo icon" />
                </div>
                <div className="lower-headings text-center mt-3">
                    <h1 className="m-0">Ticket Program</h1>
              
                </div>
                </div>
              </div>
                  <nav className="nav sidebar-menu">
                    <li>
                        <a href="/desktop" className="side-active">
                            <i className="menu-icon fa fa-th-large pe-2" aria-hidden="true"></i>
                            <span className="menu-text">Dashboard</span>
                        </a>
                    </li>
                    <li>
                        <a href="/settings">
                            <i className="bi bi-gear pe-2"></i>
                            <span className="menu-text">Settings</span>
                        </a>
                    </li>
                    <li>
                        <a href="/analytics">
                            <i className="bi bi-exclamation-circle pe-2"></i>
                            <span className="menu-text">Analytics</span>
                        </a>
                    </li>
                    <li>
                        <a href="/inquiry">
                             <i className="bi bi-exclamation-circle pe-2"></i>
                            <span className="menu-text">Inquiry</span>
                        </a>
                    </li>
                    <li>
                        <a href="/logout">
                             <i className="bi bi-exclamation-circle pe-2"></i>
                            <span className="menu-text">Logout</span>
                        </a>
                    </li>

                </nav>
          </div>  

        </aside>
        </div>
            ); 
          } else {
            return null;
          }
    }   
  }

  const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.loggedIn,
    };
  };
  export default connect(mapStateToProps)(NavBar);
  