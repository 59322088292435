import React, { useState, useRef,useEffect } from "react";
import SendIcon from "@material-ui/icons/Send";
import { Tooltip } from "@material-ui/core";
import { useGlobalContext } from "../../ContextGlobal/ContectGloval";
import documentSvg from "../../../assets/svg/document.svg";
import { Button } from "react-bootstrap";
function CaseDocument({ sendImageCallBack, room, replyUploadFileCallback }) {
    const inputRef = useRef(null);
    const context = useGlobalContext();
    const hiddenFileInput = React.useRef(null);

    const [selectedFile, setSelectedFile] = useState();
    const [selected, setSelected] = useState();
    const handleSubmitDocument = (e) => {
        //console.log("wawawa", e.target.files[0].name);
        setSelected(e.target.files[0].name);
        setSelectedFile(e.target.files[0]);
    };
    useEffect(() => {
        // console.log("i am called", context.dropImage);
        // alert("called")
        if(context?.openInputAuto==="document")
        document.getElementById("abc").click()
        
        
    }, [context.openInputAuto]);
    const handleDocumentSend = () => {
        if (context.replyMessage === "") {
            sendImageCallBack(room, selectedFile);
        } else {
            replyUploadFileCallback(
                room.roomId,
                context.replyMessage,
                selectedFile
            );
        }
        context.setReplyMessage("");
        context.setOpenWrapper(false);

        inputRef.current.value = null;
        setSelectedFile();
        setSelected(false);
    };
    return (
        <div className="d-flex flex-column align-items-center text-center w-100">
           
            <h4 className="w-50">Document</h4>
            <img src={documentSvg} />
            {/* <h6 className="w-50 mt-1">Select document file</h6> */}
            <Button
                onClick={() => {
                    hiddenFileInput.current.click();
                }}
                style={{width:"150px"}}
                className=" mt-3 btn btn-primary"
                htmlFor="filePicker"
            >
                Upload Document
            </Button>
            <div >
                <input
                id="abc"
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    type="file"
                    name="myFile"
                    onChange={handleSubmitDocument}
                />
            </div>
            {selected && (
                <div className="d-flex mt-3">
                    <h5>{selected}</h5>
                    <label
                        className="ms-3 text-danger"
                        onClick={() => {
                            setSelectedFile();
                            setSelected();
                            inputRef.current.value = null;
                        }}
                    >
                        X
                    </label>
                </div>
            )}
             <Tooltip title="Sent">
                <div className="sendButton">
                    <button
                        onClick={handleDocumentSend}
                        className="btnSend-primary "
                        disabled={selectedFile ? false : true}
                    >
                        <SendIcon />
                    </button>{" "}
                </div>
            </Tooltip>

        </div>
    );
}

export default CaseDocument;
