import {
  SYNC_ROOMS,
  SET_CLIENT,
  } from "./types";

  export const syncRooms = (allRooms) => async (dispatch) => {
    console.log("7======", allRooms);
    try {
      // const res  = await AuthDataService.authenticate(userForAuthentication);
      dispatch({
        type: SYNC_ROOMS,
        payload: allRooms,
      });
      return Promise.resolve(allRooms);
  
    }
    catch(err) {

      return Promise.reject(err);

    }
  };
  export const setClient = (client) => async (dispatch) => {
    try {
      // alert("action setClient " + JSON.stringify(client));
      // const res  = await AuthDataService.authenticate(userForAuthentication);
      dispatch({
        type: SET_CLIENT,
        payload: client,
      });
      return Promise.resolve(client);
  
    }
    catch(err) {

      return Promise.reject(err);

    }
  };


  