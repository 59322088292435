import { useCallback, useState } from "react";
import CheckIcon from "@material-ui/icons/Check";
import Cropper from "react-easy-crop";
import getCroppedImg from "./createImage";
import imageCompression from "browser-image-compression";
const EasyCrop = ({ imageCrop,setImage,setModalOpen,setAvatarUrl }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageCrop,
                croppedAreaPixels,
                rotation
            );
            console.log("donee", { croppedImage });
            // setCroppedImage(croppedImage);
            setImage(URL.createObjectURL(croppedImage))
            // const optionsCompress = {
            //     maxSizeMB: 1,
            //     maxWidthOrHeight: 500,
            //     useWebWorker: true
            // };
            imageCompression(croppedImage).then((compressedImg) => {
                console.log("imagecomp",compressedImg)

               setAvatarUrl(croppedImage)
            });
            
            setModalOpen(false)
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, rotation]);

    const onClose = useCallback(() => {
        setCroppedImage(null);
    }, []);

    return (
        <div>
            
                    <div style={{
          display: imageCrop === null || croppedImage !== null ? "none" : "block",
        }} >
                        <Cropper
                            image={imageCrop}
                            crop={crop}
                            aspect={4 / 4}
                            cropShape="round"
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                        />
                </div>
                {/* {croppedImage && (
                    <div className="gim_ic_imgDiv">
                        <img className="grp_img" src={croppedImage} />
                    </div>
                )}
                {croppedImage && <button onClick={onClose}>close</button>} */}
                <button
                className="btnSend-primary "
                    style={{
                        position: "absolute",bottom:"-19px",right:"-16px"
                    }}
                    onClick={showCroppedImage}
                >
                    <CheckIcon/>
                </button>
        </div>
    );
};

export default EasyCrop;
