import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { searchUserCreateRoom } from "../../../actions/desktop";
import { useDispatch, useSelector } from "react-redux";
import placeholder2 from "../../../assets/images/placeholder2.png"
const NewGroup = (props) => {
    const { newGroupOpen, setNewGroupOpen, createGroupCallBack } = props;
    const [invitedUser, setInvitedUser] = useState([]);
    const [invitedUserFun, setInvitedUserFun] = useState([]);
    const [groupName, setGroupName] = useState("");
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();

    const handleClose = () => {
        setNewGroupOpen(false);
    };

    // const handleSearch = (e) => {
    //     setSearch(e.target.value);
    //     if (e.key === "Enter") {
    //         const valueFromUser = e.target.value;
    //         dispatch(searchUserCreateRoom(valueFromUser));
    //     }
    // };

    // ==========================================================================================
    const handleSearch = (e) => {
        setSearch(e.target.value);
        const valueFromUser = e.target.value;
        dispatch(searchUserCreateRoom(valueFromUser));
    };
    // ==========================================================================================

    const users = useSelector((state) => {
       // console.log("users state:", state.desktop.searchUser);
        return state.desktop.searchUser;
    });

   // console.log("groupUser", users);

    const handleCheckUser = (user, e) => {
        console.log("usersss",user)
        // if(user.matrix_user_id == null && user.matrix_user_id == "")
        // return ;
        if (e.target.checked) {
            // if (user.matrix_user_id != null && user.matrix_user_id !== "") {
                            if (user.name != null && user.name !== "") {

                setInvitedUserFun((invitedUser) => [...invitedUser, user]);

                // if (invitedUser.includes(user.matrix_user_id)) {
                //     let filteredArray = invitedUser.filter(function (e) {
                //         return e !== user.matrix_user_id;
                //     });
                //     setInvitedUser(filteredArray);
                //     let filteredArrayFun = invitedUserFun.filter(function (e) {
                //         return e.matrix_user_id !== user.matrix_user_id;
                //     });
                //     setInvitedUserFun(filteredArrayFun);
                // } else {
                //     setInvitedUser((invitedUser) => [
                //         ...invitedUser,
                //         user.matrix_user_id
                //     ]);
                //     setInvitedUserFun((invitedUser) => [...invitedUser, user]);
                // }
            }
        } else {
            setInvitedUserFun(
                invitedUserFun.filter(
                    (item) => item?.displayname !== e.target.value
                )
            );

           // console.log("calledui", invitedUserFun?.user_name);
        }
    };
    const removeUser = (user) => {
        setInvitedUserFun(
            invitedUserFun.filter((item) => item?.name !== user?.name)
        );

        // if (invitedUser.includes(user.matrix_user_id)) {
        //     let filteredArray = invitedUser.filter(function (e) {
        //         return e !== user.matrix_user_id;
        //     });
        //     setInvitedUser(filteredArray);
        //     let filteredArrayFun = invitedUserFun.filter(function (e) {
        //         return e.matrix_user_id !== user.matrix_user_id;
        //     });
        //     setInvitedUserFun(filteredArrayFun);
        // }
    };

    const handleCreateGroup = () => {
        if (groupName !== "") {
            if (invitedUserFun.length !== 0) {
               
                const newUsers = invitedUserFun.map((user) => ({
                    ...user,
                    matrix_user_id:user.name, 
                  }));
                  let options = {
                    visibility: "private",
                    invite: invitedUserFun,
                    name: groupName
                };
                
                 // console.log("newUsers",newUsers)
                //// console.log("optioncalled",options)
                createGroupCallBack(options);
                setNewGroupOpen(false);
            } else {
                alert("User not selected!");
            }
        } else {
            alert("Room name Empty!");
        }
    };
    const checkedFun = (user) => {
        return Boolean(
            invitedUserFun.find((item) => {
                return item?.name == user.name;
            })
        );
    };

    return (
        <Modal size="md" show={newGroupOpen} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create Group</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="paragraphGroup">
                    Start a conversation with someone using their name, email
                    address or username.
                </p>
                {/* (like @user1:devm.vjoinlife.com) */}
                <div className="form-outline">
                    <input
                        type="text"
                        placeholder="Name"
                        className="form-control mt-2"
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                    <div>
                        <div className="mt-2" style={{ display: "flex",flexWrap:"wrap" }}>
                            {invitedUserFun.map((user, i) => (
                                <>
                                <div className="group-member"  key={i}>
                                    <div className="img-sec me-2">
                        <img
                        style={{width:"25px",height:"25px"}}
                            src={placeholder2}
                            className="rounded-circle"
                            alt="Avatar"
                        />
</div>
                                    <div style={{color:"#3b4a54"}}>
                                        {user.displayname}
                                        {/* <div className="">
                                        {user.user_email}
                                    </div> */}
                                    </div>
                                    <div>
                                        <i  style={{paddingLeft:"13px",color:"#8696a0"}}
                                            className="fa ml-2 fa-close "
                                            onClick={() => removeUser(user)}
                                        ></i>
                                    </div>
                                </div>
                                </>
                            ))}
                        </div>
                        <input
                            type="search"
                            placeholder="Search user"
                            className="form-control mt-2"
                            // onKeyDown={handleSearch}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
                <div className="list-group vh-30 d-block" style={{ zIndex: 1 }}>
                    {search &&
                        (users?.length > 0
                            ? users.map((user, i) => (
                                user.matrix_user_id===null?"":
                                  <div key={i}>
                                      <label className="list-group-item listItemBackground">
                                          <input
                                              className="form-check-input me-1"
                                              type="checkbox"
                                              value={user?.displayname}
                                              checked={checkedFun(user)}
                                              onChange={(e) =>
                                                  handleCheckUser(user, e)
                                              }
                                          />
                                          {user.displayname}
                                          <div className="ms-2 me-auto fw-normal">
                                              {user.name}
                                          </div>
                                      </label>
                                  </div>
                                            
                              ))
                            : "")}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    style={{ width: "150px" }}
                    variant="primary"
                    onClick={handleCreateGroup}
                >
                    Create Group
                </Button>
                {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default NewGroup;
