import React from "react";

import SearchBar from "../../../assets/svg/search.svg";
import "./styles.css";

function Search(props) {


    const {
        Title = "title",
        placeholder = "Search message group threads...",
        type = "text",
        value,

        onHandleChange,
        onClickChange,
        className,
        msgQuery,
        setMsgQuery,
        searchMsg,
        searchWindow,
    } = props;



    return (
        <div className={`search_container ${className}`}>
            <div className="search_input">
                {
                    !searchMsg ? (
                        <input

                            className="Search_ heading3"
                            placeholder={placeholder}
                            type={type}
                            onClick={onClickChange}
                            // autoFocus
                            // value={searchMsg ? msgQuery : null}
                            onChange={onHandleChange}
                        />
                    ) : (
                        <input
                            id="searchMsgInput"
                            className="Search_ heading3"
                            placeholder={placeholder}
                            type={type}
                            value={msgQuery}
                            onChange={(e) => setMsgQuery(e.target.value)}
                        />

                    )
                }

            </div>
            <div className="search_logo">
                <img src={SearchBar} alt="search" className="logo_" />
            </div>
        </div>
    );
}

export default Search;
