import http from "../http-common";
import axios from "axios";

class AuthDataService {
  register(user) {
    return http.post("/register_v2", user);
  }

  authenticate(user) {
    return http.post(`/login_matrix`, user);

   // return http.post(`/admin_login_matrix`, user);
  }
}
export default new AuthDataService();