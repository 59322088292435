import {
  AUTHENTICATE,
  LOGOUT,
  REGISTER,
  } from "../actions/types";
  const initialState = [];
  function authReducer(user = initialState, action) {
    const { type, payload } = action;
    // alert(JSON.stringify(payload.token))
    switch (type) {
      case AUTHENTICATE:
      return {
          loggedIn: true,
          token: payload.token,
          user: payload
        };
      case REGISTER:
        return {
          loggedIn: false,
          user: payload
        };
        case LOGOUT:
          return {
            loggedIn: false,
            user: payload
          };
  
      default:
        return user;
    }
  };
  export default authReducer;