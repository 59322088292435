import { MenuItem, SwipeableDrawer } from "@material-ui/core";
import React, { useState,useCallback } from "react";
import Webcam from "react-webcam";
import placeholder2 from "../../assets/images/1.png";
import EasyCrop from "./easySlider";
import { Modal, Box, Typography } from "@mui/material";
import viewPhoto from "./viewPhoto";
import { PhotoProvider, PhotoView } from "react-photo-view";

import PopoverReuse from "../desktop/DesktopComponents/ReusableComponnet/Popover";
import ModalReuse from "../desktop/DesktopComponents/ReusableComponnet/ModalReuse";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 510,
    height: 600,
    bgcolor: "silver",
    boxShadow: 24,
    p:4

};
const styleCamera = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 510,
    height: 600,
    bgcolor: "white",
    boxShadow: 24,

};
function ProfileEdit(props) {

    const { opens, setOpen, userName, setAvatarUrl } = props;
    const hiddenFileInput = React.useRef(null);
    const [modalOpen, setModalOpen] = useState(false);

    const [image, setImage] = useState(placeholder2);
    const [imageCrop, setImageCrop] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [viewProfileSection, setViewProfileSection] = useState("");
    const webcamRef = React.useRef(null);

    const videoConstraints = {
        width: 220,
        height: 200,
        facingMode: "user"
    };
    const handleSubmitImage = (e) => {
        setModalOpen(true);
        const file = e.target.files[0];
        // console.log("image profile",file)
        // setImage(URL.createObjectURL(file))
        setViewProfileSection("upload-photo")
        setImageCrop(URL.createObjectURL(file));
    };
    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        fetch(imageSrc)
                .then((res) => res.blob())
                .then((blob) => {
                    const file = new File([blob], "img.jpeg");
setImageCrop(URL.createObjectURL( file))
setViewProfileSection("upload-photo")
setModalOpen(true);

        console.log("imageREsourcse",file)

                })
        // setImage(imageSrc);
    });
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const showProfileClick = (val) => {
        console.log("photo-view", val);
        switch (val) {
            case "view-photo":
                // setViewProfileSection(val)
                // document.getElementById("view").click()

                break;
            case "take-photo":
                setViewProfileSection("take-photo")
                setModalOpen(true)

                break;
            case "View-photo":

                break;
            case "upload-photo":

                break;
            case "remove-photo":
                break;
            default:
                break;
        }
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setOpen(open);
    };
    return (
        <div>
            <SwipeableDrawer
                anchor={"right"}
                open={opens}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                style={{ zIndex: 1, width: "426px" }}
            >
                <div className="groupInfo_main">
                    <div className="gim_header_top">
                        <span
                            className="fa fa-close close_icon"
                            onClick={() => setOpen(false)}
                        />
                        <span className="ht_title"> Profile </span>
                    </div>

                    <div
                        
                        className="gim_img_cont"
                    >
                        <div onClick={handleClick} className="gim_ic_imgDiv">
                            <img
                                className="grp_img"
                                src={image}
                                // src= {grp}
                            />
                            <div className="change_img">
                                <span className="fa fa-solid fa-camera iu_icon">
                                    {" "}
                                </span>
                                <span className="ci_text">
                                    {" "}
                                    Change group icon{" "}
                                </span>
                            </div>
                        </div>

                        <div className="grp_details">
                            <span className="grp_name"> {userName} </span>

                            {/* <span className="grp_members_count"> Groupx  </span> */}
                        </div>
                    </div>
                    <PopoverReuse
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        cssClass={"topbar-profile"}
                    >
                        <MenuItem>
                            <PhotoProvider>
                                <div>
                                    <PhotoView src={image}>
                                        <p
                                            // onClick={() => {
                                            //     // showProfileClick("view-photo");
                                            //     handleClose();
                                            // }}
                                            className="paragraph-action"
                                        >
                                            {" "}
                                            View Photo
                                        </p>
                                    </PhotoView>
                                </div>
                            </PhotoProvider>
                        </MenuItem>
                        <MenuItem>
                            <p onClick={() => {
                                                showProfileClick("take-photo");
                                                handleClose();
                                            }}  className="paragraph-action"> Take Photo</p>
                        </MenuItem>
                        <MenuItem>
                            <p 
                            onClick={() => {
                            hiddenFileInput.current.click();handleClose()
                        }} 
                         className="paragraph-action"> Upload Photo</p>
                        </MenuItem>
                        <MenuItem>
                            <p className="paragraph-action"> Remove Photo</p>
                        </MenuItem>
                    </PopoverReuse>
                    <div>
                        <input
                            id="abc"
                            type="file"
                            name="myImage"
                            ref={hiddenFileInput}
                            accept="image/*"
                            onChange={handleSubmitImage}
                            style={{ display: "none" }}
                        />
                    </div>
                    <div className="other_func_container">
                        <div className="enc_div">
                            <div className="enc_icon_div">
                                <span className="fa fa-solid fa-lock enc_icon"></span>
                            </div>
                            <div className="enc_desc">
                                <span className="enc_d_title">
                                    {" "}
                                    Encryption{" "}
                                </span>
                                <span className="enc_d_detail">
                                    {" "}
                                    Messages are end-to-end encrypted. Click to
                                    learn more.{" "}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* <Button onClick={handleOpen}>Open modal</Button> */}
                        {viewProfileSection==="upload-photo"?
                        <ModalReuse
                            open={modalOpen}
                            setModalOpen={setModalOpen}
                            
                        >
                            <Box sx={style}>
                                <div className="gim_header_top inner_box_crop_image">
                                    <span
                                        style={{ color: "white" }}
                                        className="fa fa-close close_icon"
                                        onClick={() => setModalOpen(false)}
                                    />
                                    <span style={{ color: "white" }}>
                                        {" "}
                                        Drag the image to adjust{" "}
                                    </span>
                                </div>

                                <EasyCrop
                                    setAvatarUrl={setAvatarUrl}
                                    setModalOpen={setModalOpen}
                                    setImage={setImage}
                                    imageCrop={imageCrop}
                                />
                            </Box>
                        </ModalReuse>
   :                        viewProfileSection==="take-photo"?
   <ModalReuse
       open={modalOpen}
       setModalOpen={setModalOpen}
       
   >
       <Box sx={styleCamera}>
           <div className="gim_header_top inner_box_crop_image">
               <span
                   style={{ color: "white" }}
                   className="fa fa-close close_icon"
                   onClick={() => setModalOpen(false)}
               />
               <span style={{ color: "white" }}>
                   {" "}
                   Take Photo{" "}
               </span>
           </div>

           <div style={{marginTop:"50px"}} className="d-flex flex-column">
                                <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                    className="w-100"
                                    style={{ height: "30rem" }}
                                />
                                <div className="captureButton-profile">
                                    <button
                                        className="btn btn-primary btn-circle"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            capture();
                                        }}
                                    >
                                        <i className="fa fa-camera"></i>
                                    </button>
                                </div>
                            </div>
       </Box>
   </ModalReuse>
:""}

                        {/* <EasyCrop image={image} modalOpen={modalOpen} setModalOpen={setModalOpen}/> */}
                    </div>
                </div>
            </SwipeableDrawer>
        </div>
    );
}

export default ProfileEdit;
