import { combineReducers } from "redux";
import auth from "./auth";
import desktop from "./desktop";
import messagingSDK from "./messagingSDK";
import statuses from "./statuses";
import stages from "./stages";
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
  /* your app’s top-level reducers */
  auth,
  desktop,
  stages,
  statuses,  
  messagingSDK, 
})

const rootReducer = (state, action) => {
  // console.log("actioneri",action)
  if (action.type === 'LOGOUT') {
    // storage.removeItem('persist:root')
    // storage.removeItem('persist:otherKey')
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
export default rootReducer;