import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";

import jwt_decode from "jwt-decode";

const PrivateRoute = ({ token, currentUser, isLoggedIn, component: Component, ...rest }) => {
  // const token = token1;
  console.log("thiscalled",token)
  // const currentUser = localStorage.getItem("currentUser")
  let errors = {

  };
  let valid = token  && currentUser;
  if (!valid || valid == "undefined"){
    errors.invalidToken='Login token is invalid, please login again'  
    
  } else {
    const user = currentUser;
    var decoded = jwt_decode(token);
    let unix_timestamp = decoded.exp;
    var date = new Date(unix_timestamp * 1000);
  
    var now = new Date();
    if (date <= now){
      //expired 
      errors.expired='Session has expired, please login again'  
      
    }  

  
  }
  if (!isLoggedIn) {
    errors.expired='User is logged out, please login again';
    valid=false;
  } 
    // client.on("event", function(event){
    //   console.log(event.getType());
    //   console.log(event);
    // })
    // client.on("Room.timeline", function(event, room, toStartOfTimeline) {
    //   console.log(event);
    //   if (event.type == 'm.room.encrypted'){
    //     // console.log(even);
    //   }
    //   console.log(event.event);
    // });
    // console.log("client========", client);
    // var rooms = client.getRooms();
    // console.log("before get rooms details");
    // rooms.forEach(room => {
    //   console.log("get rooms details");
    //   console.log("room.summary=====", room.summary);
    //   console.log(room.roomName);
    //   console.log(room.roomId);
    // });
    // var rooms = client.getRooms();
    // rooms.forEach(room => {
    //     var members = room.getJoinedMembers();
    //     members.forEach(member => {
    //         console.log(member.name);
    //     });
    // });
    // var rooms = client.getRooms();
    // rooms.forEach(room => {
    //     room.timeline.forEach(t => {
    //         console.log(JSON.stringify(t.event.content));
    //     });
    // });       


  useEffect(() => {
    // if(localStorage.getItem("Reaction")==true)
    // {
    //   console.log("ahmad")
    //   localStorage.setItem("Reaction",'')
    // }
    // else{
     //this is called only once when application load only and save this other wise its not called
localStorage.setItem("tokenHeaderAxios",token);
    // axios.defaults.headers.common["token"] = token;
  // }
    // axios.defaults.headers.common["token"] = token;
  }, [token]);

  return (
    <Route
      {...rest}
      render={(props) =>
        valid ? <Component {...props} /> : <Redirect error={errors} to="/" />
      }
    />
  );
};

// export default PrivateRoute;
const mapStateToProps = (state) => {
  // console.log("calledme",state.auth?.user)
  return {
      isLoggedIn: state.auth.loggedIn,
      token: state.auth?.token,
      currentUser: state?.auth?.user,
  };
};
export default connect(mapStateToProps)(PrivateRoute);

