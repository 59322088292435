import React, { useEffect } from "react";
import { Popover } from "@mui/material";

export default function PopoverReuse({
    id,
    children,
    open,
    anchorEl,
    transformOrigin,
    onClose,anchorOrigin,cssClass

}) {


    // console.log("allmessages",id,
    // children,
    // open,
    // anchorEl,
    // onClose)
    return (
        <Popover
        classes={{ paper:cssClass}}
            id={id}
            open={open}
            // anchorPosition={{ top: 228, left: 518 }}
            // anchorReference="anchorPosition"

            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            {children}
        </Popover>
    );
}
